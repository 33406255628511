import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";
import { create } from 'zustand';

var _create = create(function (set) {
  return {
    activeProjectCategoryUID: null,
    setActiveProjectCategoryUID: function setActiveProjectCategoryUID(uid) {
      return set(function (state) {
        return Object.assign({}, state, {
          activeProjectCategoryUID: uid
        });
      });
    },
    clearActiveProjectCategoryUID: function clearActiveProjectCategoryUID() {
      return set(function (state) {
        return Object.assign({}, state, {
          activeProjectCategoryUID: null
        });
      });
    }
  };
}),
    useStore = _create[0];

export { useStore };