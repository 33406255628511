import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.fill";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { SVG } from 'system';
import { ReactComponent as AssetLogoSVG } from 'src/assets/logo.svg';
var colorSchemes = {
  light: {
    color: 'gray.90',
    fill: null // specifying fill will override the color and not use the default blue

  },
  dark: {
    color: 'brown.30',
    fill: null
  },
  black: {
    color: 'black',
    fill: 'black'
  }
};
export var Logo = function Logo(_ref) {
  var _ref$colorScheme = _ref.colorScheme,
      colorScheme = _ref$colorScheme === void 0 ? 'dark' : _ref$colorScheme,
      props = _objectWithoutPropertiesLoose(_ref, ["colorScheme"]);

  var scheme = colorSchemes[colorScheme];
  return React.createElement(SVG, Object.assign({
    x: 226,
    y: 74,
    svg: AssetLogoSVG,
    color: scheme.color,
    fill: scheme.fill
  }, props));
};