import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { linearScale } from 'styled-system-scale';
import { themeGet } from '@styled-system/theme-get';
import { Text, Button, SVG } from 'system';
import { ReactComponent as AssetIconArrowDownSVG } from 'src/assets/icon-arrow-down.svg';

var _StyledButton = _styled(Button).withConfig({
  displayName: "ScrollDownButton___StyledButton",
  componentId: "sc-2q2st8-0"
})(["&:hover,&:focus{color:", ";}"], function (p) {
  return p._css;
});

export var ScrollDownButton = function ScrollDownButton(_ref) {
  var _ref$children = _ref.children,
      children = _ref$children === void 0 ? 'Scroll' : _ref$children,
      props = _objectWithoutPropertiesLoose(_ref, ["children"]);

  return React.createElement(_StyledButton, Object.assign({
    display: "inline-flex",
    alignItems: "center",
    transitionProperty: "color",
    outline: "0"
  }, props, {
    _css: themeGet('colors.blue.40')
  }), React.createElement(Text, {
    mrScale: "t",
    fontSizeScale: "m",
    fontWeight: "light",
    lineHeight: "solid"
  }, children), React.createElement(SVG, {
    svg: AssetIconArrowDownSVG,
    x: 24,
    y: 21,
    width: linearScale('16px', '31px', {
      count: 5
    }),
    color: "currentColor"
  }));
};