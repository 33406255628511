import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.date.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.parse-int";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.parse-int";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.parse-int";
import "core-js/modules/es6.regexp.search";
import { useState, useLayoutEffect, useEffect } from 'react';
import { take } from 'tiny-compose-fns/noFp';
import { isBrowser } from 'helpers';
export var useLoadMorePagination = function useLoadMorePagination(_ref) {
  var collection = _ref.collection,
      _ref$perPage = _ref.perPage,
      perPage = _ref$perPage === void 0 ? 10 : _ref$perPage,
      _ref$initialPage = _ref.initialPage,
      initialPage = _ref$initialPage === void 0 ? 1 : _ref$initialPage,
      _ref$paramKey = _ref.paramKey,
      paramKey = _ref$paramKey === void 0 ? 'page' : _ref$paramKey,
      _ref$enabled = _ref.enabled,
      enabled = _ref$enabled === void 0 ? true : _ref$enabled;

  var _useState = useState(function () {
    if (!isBrowser) return {
      page: initialPage,
      loadedCollection: take(initialPage * perPage, collection)
    }; // if use_load_more_feature is set to false, return null

    if (!enabled) return {
      page: null,
      loadedCollection: collection
    };
    var searchParams = new URLSearchParams(location.search);
    var currPage = Number.parseInt(searchParams.get(paramKey));
    if (currPage) return {
      page: currPage,
      loadedCollection: take(currPage * perPage, collection)
    };
    return {
      page: initialPage,
      loadedCollection: take(initialPage * perPage, collection)
    };
  }),
      state = _useState[0],
      set = _useState[1];

  var page = state.page,
      loadedCollection = state.loadedCollection;
  var canLoadMore = page < collection.length / perPage && enabled;

  var nextPage = function nextPage() {
    var searchParams = new URLSearchParams(location.search);
    searchParams.set(paramKey, Number.parseInt(page) + 1);
    history.replaceState(null, '', window.location.pathname + "?" + searchParams.toString());
    set(function (state) {
      return Object.assign({}, state, {
        page: state.page + 1
      });
    });
  }; // If page changes, take the appropriate amount of items.


  useLayoutEffect(function () {
    if (!enabled) {
      set(function (state) {
        return Object.assign({}, state, {
          loadedCollection: collection
        });
      });
      return;
    }

    set(function (state) {
      return Object.assign({}, state, {
        loadedCollection: take(page * perPage, collection)
      });
    });
  }, [page, collection, perPage, enabled]); // If collection as a whole changes, e.g. the search query changes, we need
  // to reset the current page.

  useEffect(function () {
    var searchParams = new URLSearchParams(location.search);
    var currPage = Number.parseInt(searchParams.get(paramKey));
    set(function (state) {
      return Object.assign({}, state, {
        page: currPage || initialPage
      });
    });
  }, [collection, initialPage, paramKey]);
  return [loadedCollection, nextPage, canLoadMore];
};