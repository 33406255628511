import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useState } from 'react';
import { useGesture } from 'react-use-gesture';
import { useStore, useProjectCategoriesData } from 'src/hooks';
import { Box, AspectRatio, Image, ImageContainer, Link } from 'system';
import { Heading, Subheading } from 'src/components';
var variants = {
  small: {
    ratioX: [335, null, 12],
    ratioY: [180, null, 10],
    pScale: 'l',
    nameFontSizeScale: ['l', 'm', null, null, 'l'],
    locationFontSizeScale: ['s', null, null, null, 'b']
  },
  large: {
    ratioX: [335, 12],
    ratioY: [400, 10],
    pScale: 'l',
    nameFontSizeScale: 'l',
    locationFontSizeScale: ['s', null, null, 'b']
  },
  wide: {
    ratioX: 16,
    ratioY: 9,
    pScale: 'l',
    nameFontSizeScale: 'm',
    locationFontSizeScale: ['s', null, null, null, 'b']
  },
  wideSmall: {
    ratioX: 16,
    ratioY: 9,
    pScale: 'm-',
    nameFontSizeScale: ['b', null, null, null, 'm'],
    locationFontSizeScale: ['s-', null, null, null, 'b']
  }
};

var _StyledLink = _styled(Link).withConfig({
  displayName: "ProjectCard___StyledLink",
  componentId: "sc-12ysc84-0"
})({
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
});

var _StyledImage = _styled(Image).withConfig({
  displayName: "ProjectCard___StyledImage",
  componentId: "sc-12ysc84-1"
})(["pointer-events:none;"]);

export var ProjectCard = function ProjectCard(_ref) {
  var categoryUID = _ref.categoryUID,
      href = _ref.href,
      imageAlt = _ref.imageAlt,
      imageFluid = _ref.imageFluid,
      imageURL = _ref.imageURL,
      location = _ref.location,
      name = _ref.name,
      _ref$variant = _ref.variant,
      variantName = _ref$variant === void 0 ? 'small' : _ref$variant,
      innerProps = _ref.innerProps,
      props = _objectWithoutPropertiesLoose(_ref, ["categoryUID", "href", "imageAlt", "imageFluid", "imageURL", "location", "name", "variant", "innerProps"]);

  var _useState = useState(false),
      isFocused = _useState[0],
      setIsFocused = _useState[1];

  var handleFocus = function handleFocus(isFocused) {
    return setIsFocused(function () {
      return Boolean(isFocused);
    });
  };

  var bind = useGesture({
    onHover: function onHover(_ref2) {
      var hovering = _ref2.hovering;
      return handleFocus(hovering);
    },
    onDrag: function onDrag(_ref3) {
      var down = _ref3.down;
      return handleFocus(down);
    }
  });
  var activeProjectCategoryUID = useStore(function (state) {
    return state.activeProjectCategoryUID;
  });
  var projectCategory = useProjectCategoriesData(activeProjectCategoryUID || categoryUID);
  var projectCategoryColor = (projectCategory === null || projectCategory === void 0 ? void 0 : projectCategory.color) || 'gray.10';
  var variant = variants[variantName];
  return React.createElement(_StyledLink, Object.assign({
    href: href,
    onBlur: function onBlur() {
      return handleFocus(false);
    },
    onFocus: function onFocus() {
      return handleFocus(true);
    },
    outline: 0,
    overflow: "hidden"
  }, bind(), props), React.createElement(AspectRatio, Object.assign({
    x: variant === null || variant === void 0 ? void 0 : variant.ratioX,
    y: variant === null || variant === void 0 ? void 0 : variant.ratioY,
    bg: "gray.10",
    color: "white",
    position: "relative"
  }, innerProps), React.createElement(ImageContainer, {
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    position: "absolute"
  }, (imageFluid || imageURL) && React.createElement(_StyledImage, {
    fluid: imageFluid,
    src: imageURL,
    alt: imageAlt
  })), React.createElement(Box, {
    display: [null, null, 'none'],
    backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
    height: "5rem",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0
  }), React.createElement(Box, {
    height: "100%",
    pScale: variant.pScale,
    plScale: [0, null, variant.pScale],
    position: "relative"
  }, React.createElement(Box, {
    bg: projectCategoryColor,
    bottom: 0,
    left: 0,
    opacity: isFocused ? [0.5, null, 0.8] : 0,
    position: "absolute",
    right: 0,
    top: 0,
    transitionProperty: "opacity"
  }), React.createElement(Box, {
    borderLeft: ['14px solid', null, 0],
    borderLeftColor: projectCategory === null || projectCategory === void 0 ? void 0 : projectCategory.color,
    opacity: [1, null, isFocused ? 1 : 0],
    position: "relative",
    pxScale: ['l', null, 0],
    transitionProperty: "opacity",
    transform: [null, null, 'translateY(-10%)']
  }, name && React.createElement(Heading, {
    as: "p",
    mbScale: "t-",
    fontWeight: "light",
    fontSizeScale: variant.nameFontSizeScale
  }, name), location && React.createElement(Subheading, {
    as: "p",
    fontSizeScale: variant.locationFontSizeScale
  }, location)))));
};