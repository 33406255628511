import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useState } from 'react';
import { useGesture } from 'react-use-gesture';
import { useNewsCategoriesData } from 'src/hooks';
import { Flex, Image, AspectRatio, ImageContainer, Link, Text } from 'system';
import { Heading, Subheading } from 'src/components';

var _StyledLink = _styled(Link).withConfig({
  displayName: "FeaturedNewsCard___StyledLink",
  componentId: "sc-1vrytr1-0"
})(["", ""], function (p) {
  return {
    '&:hover, &:focus': {
      color: p.theme.colors.blue[40]
    }
  };
});

export var FeaturedNewsCard = function FeaturedNewsCard(_ref) {
  var _newsCategory$data, _newsCategory$data$ti;

  var imageURL = _ref.imageURL,
      imageAlt = _ref.imageAlt,
      imageFluid = _ref.imageFluid,
      href = _ref.href,
      title = _ref.title,
      categoryUID = _ref.categoryUID,
      date = _ref.date,
      props = _objectWithoutPropertiesLoose(_ref, ["imageURL", "imageAlt", "imageFluid", "href", "title", "categoryUID", "date"]);

  var _useState = useState(false),
      isFocused = _useState[0],
      setIsFocused = _useState[1];

  var handleFocus = function handleFocus(isFocused) {
    return setIsFocused(function () {
      return Boolean(isFocused);
    });
  };

  var bind = useGesture({
    onHover: function onHover(_ref2) {
      var hovering = _ref2.hovering;
      return handleFocus(hovering);
    },
    onDrag: function onDrag(_ref3) {
      var down = _ref3.down;
      return handleFocus(down);
    }
  });
  var newsCategory = useNewsCategoriesData(categoryUID);
  return React.createElement(Flex, Object.assign({
    flexDirection: "column",
    pbScale: "t"
  }, props), React.createElement(Link, Object.assign({
    href: href,
    onBlur: function onBlur() {
      return handleFocus(false);
    },
    onFocus: function onFocus() {
      return handleFocus(true);
    },
    display: "block",
    mbScale: "m",
    flexGrow: "1",
    tabIndex: "-1"
  }, bind()), React.createElement(ImageContainer, {
    height: "100%",
    bg: "gray.10"
  }, React.createElement(AspectRatio, {
    x: [16, null, null, null, 16],
    y: [9, null, null, null, 7],
    height: "100%"
  }, (imageFluid || imageURL) && React.createElement(Image, {
    src: imageURL,
    alt: imageAlt,
    fluid: imageFluid
  })))), newsCategory && React.createElement(Subheading, {
    color: "green.30",
    mbScale: "s"
  }, React.createElement(_StyledLink, {
    href: newsCategory === null || newsCategory === void 0 ? void 0 : newsCategory.url,
    transitionProperty: "color"
  }, newsCategory === null || newsCategory === void 0 ? void 0 : (_newsCategory$data = newsCategory.data) === null || _newsCategory$data === void 0 ? void 0 : (_newsCategory$data$ti = _newsCategory$data.title) === null || _newsCategory$data$ti === void 0 ? void 0 : _newsCategory$data$ti.text)), title && React.createElement(Heading, {
    fontWeight: "light",
    fontSizeScale: "m+",
    mbScale: "s",
    width: [1, null, null, null, 6 / 8]
  }, React.createElement(Link, Object.assign({
    href: href,
    transitionProperty: "color",
    color: isFocused ? 'blue.40' : 'inherit',
    onBlur: function onBlur() {
      return handleFocus(false);
    },
    onFocus: function onFocus() {
      return handleFocus(true);
    }
  }, bind()), title)), date && React.createElement(Text, {
    as: "p"
  }, date));
};