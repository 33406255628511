import "core-js/modules/es6.string.link";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.string.link";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.string.link";
import "core-js/modules/es6.function.name";
import _regeneratorRuntime from "@babel/runtime/regenerator";
import "regenerator-runtime/runtime";
import "core-js/modules/es6.regexp.search";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useEffect, useRef } from 'react';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import { linearScale } from 'styled-system-scale';
import delay from 'delay';
import { useSpring, useTrail, a } from 'react-spring';
import { themeGet } from '@styled-system/theme-get';
import { Box, Grid, Text, Link } from 'system';
import { Logo } from 'src/components';
import { usePrimaryNavigationData } from 'src/hooks';
import { SearchInput } from './SearchInput';
var AnimatedLogo = a(Logo);

var _StyledLink = _styled(Link).withConfig({
  displayName: "SlidingNav___StyledLink",
  componentId: "sc-1vo60cs-0"
})(["", ""], function (props) {
  return {
    textTransform: 'lowercase',
    '&:hover, &:focus-within': {
      color: themeGet('colors.blue.40')(props)
    }
  };
});

export var SlidingNav = function SlidingNav(_ref) {
  var isActive = _ref.isActive,
      closeNav = _ref.closeNav,
      props = _objectWithoutPropertiesLoose(_ref, ["isActive", "closeNav"]);

  var primaryNavData = usePrimaryNavigationData();
  var containerRef = useRef(null);
  var config = {
    tension: 160,
    friction: 32
  };

  var _useSpring = useSpring(function () {
    return {
      transform: 'translate3d(100%, 0, 0)',
      config: config
    };
  }),
      containerStyle = _useSpring[0],
      setContainerStyle = _useSpring[1];

  var _useSpring2 = useSpring(function () {
    return {
      transform: 'translate3d(-75%, 0, 0)',
      config: config
    };
  }),
      contentStyle = _useSpring2[0],
      setContentStyle = _useSpring2[1];

  var _useTrail = useTrail(primaryNavData.length + 1, function () {
    return {
      opacity: 0
    };
  }),
      itemStyles = _useTrail[0],
      setItemStyles = _useTrail[1];

  var handleSubmit = function handleSubmit(e) {
    e.preventDefault();
    closeNav();
    navigate("/search/?" + queryString.stringify({
      query: e.target.search.value
    }));
  };

  useEffect(function () {
    var asyncAnimation = function asyncAnimation() {
      var container;
      return _regeneratorRuntime.async(function asyncAnimation$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              container = containerRef.current;

              if (!isActive) {
                _context.next = 10;
                break;
              }

              container.style.visibility = 'visible';
              setContainerStyle({
                transform: 'translate3d(0%, 0, 0)'
              });
              setContentStyle({
                transform: 'translate3d(0%, 0, 0)'
              });
              _context.next = 7;
              return _regeneratorRuntime.awrap(delay(200));

            case 7:
              setItemStyles({
                opacity: 1
              });
              _context.next = 15;
              break;

            case 10:
              setItemStyles({
                opacity: 0
              });
              _context.next = 13;
              return _regeneratorRuntime.awrap(delay(400));

            case 13:
              setContainerStyle({
                transform: 'translate3d(100%, 0, 0)'
              });
              setContentStyle({
                transform: 'translate3d(-75%, 0, 0)',
                onRest: function onRest() {
                  return container.style.visibility = 'hidden';
                }
              });

            case 15:
            case "end":
              return _context.stop();
          }
        }
      });
    };

    asyncAnimation();
  }, [isActive, setContainerStyle, setContentStyle, setItemStyles]);
  return React.createElement(Grid, Object.assign({
    ref: containerRef,
    as: a(Text),
    "aria-hidden": !isActive,
    bg: "brown.30",
    color: "white",
    fontFamily: "sansExtended",
    fontSizeScale: "xxl",
    fontWeight: "thin",
    gridTemplateRows: "1fr auto",
    height: "100vh",
    lineHeight: "title",
    overflowX: "hidden",
    overflowY: "auto",
    pScale: "xl+",
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: "slidingNav",
    width: ['100vw', null, '45%'],
    style: containerStyle
  }, props), React.createElement(Box, {
    as: "nav"
  }, React.createElement(Grid, {
    as: a.ul,
    style: contentStyle,
    alignContent: "center",
    height: "100%",
    justifyContent: "start"
  }, primaryNavData.map(function (item, i) {
    var _item$primary, _item$primary2, _item$primary2$link, _item$primary3, _item$primary3$link, _item$primary4;

    return React.createElement(Box, {
      as: a.li,
      style: itemStyles[i],
      key: item === null || item === void 0 ? void 0 : (_item$primary = item.primary) === null || _item$primary === void 0 ? void 0 : _item$primary.name
    }, React.createElement(_StyledLink, {
      href: item === null || item === void 0 ? void 0 : (_item$primary2 = item.primary) === null || _item$primary2 === void 0 ? void 0 : (_item$primary2$link = _item$primary2.link) === null || _item$primary2$link === void 0 ? void 0 : _item$primary2$link.url,
      target: item === null || item === void 0 ? void 0 : (_item$primary3 = item.primary) === null || _item$primary3 === void 0 ? void 0 : (_item$primary3$link = _item$primary3.link) === null || _item$primary3$link === void 0 ? void 0 : _item$primary3$link.target,
      fontWeight: "thin",
      outline: "none",
      transitionProperty: "color"
    }, item === null || item === void 0 ? void 0 : (_item$primary4 = item.primary) === null || _item$primary4 === void 0 ? void 0 : _item$primary4.name));
  }), React.createElement(Box, {
    as: a.li,
    mtScale: "xl",
    style: itemStyles[primaryNavData.length]
  }, React.createElement(Box, {
    as: "form",
    onSubmit: handleSubmit
  }, React.createElement(SearchInput, null))))), React.createElement(Link, {
    href: "/",
    width: linearScale('6rem', '8rem', {
      count: 5
    }),
    justifySelf: "end",
    style: contentStyle
  }, React.createElement(AnimatedLogo, {
    colorScheme: "light"
  })));
};