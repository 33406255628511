import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.reduce";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.object.assign";
import staticQueryData from "../../public/static/d/1759947043.json";
import { getRichText } from 'helpers';
var markerTypes = {
  'A - PVA Logo': 'pvaLogo',
  'P - Parking': 'parking'
};
export var useMapData = function useMapData(uid) {
  var _queryData$allPrismic, _queryData$allPrismic2, _queryData$allPrismic3;

  var queryData = staticQueryData.data;
  var normalized = queryData === null || queryData === void 0 ? void 0 : (_queryData$allPrismic = queryData.allPrismicMap) === null || _queryData$allPrismic === void 0 ? void 0 : (_queryData$allPrismic2 = _queryData$allPrismic.nodes) === null || _queryData$allPrismic2 === void 0 ? void 0 : (_queryData$allPrismic3 = _queryData$allPrismic2.reduce) === null || _queryData$allPrismic3 === void 0 ? void 0 : _queryData$allPrismic3.call(_queryData$allPrismic2, function (acc, map) {
    var _map$data, _map$data$starting_co, _map$data2, _map$data2$starting_c, _map$data3, _map$data3$markers, _map$data3$markers$ma;

    acc[map === null || map === void 0 ? void 0 : map.uid] = Object.assign({}, map, {
      longitude: map === null || map === void 0 ? void 0 : (_map$data = map.data) === null || _map$data === void 0 ? void 0 : (_map$data$starting_co = _map$data.starting_coordinates) === null || _map$data$starting_co === void 0 ? void 0 : _map$data$starting_co.longitude,
      latitude: map === null || map === void 0 ? void 0 : (_map$data2 = map.data) === null || _map$data2 === void 0 ? void 0 : (_map$data2$starting_c = _map$data2.starting_coordinates) === null || _map$data2$starting_c === void 0 ? void 0 : _map$data2$starting_c.latitude,
      markers: map === null || map === void 0 ? void 0 : (_map$data3 = map.data) === null || _map$data3 === void 0 ? void 0 : (_map$data3$markers = _map$data3.markers) === null || _map$data3$markers === void 0 ? void 0 : (_map$data3$markers$ma = _map$data3$markers.map) === null || _map$data3$markers$ma === void 0 ? void 0 : _map$data3$markers$ma.call(_map$data3$markers, function (marker) {
        var _marker$name, _marker$geopoint, _marker$geopoint2;

        return {
          name: marker === null || marker === void 0 ? void 0 : (_marker$name = marker.name) === null || _marker$name === void 0 ? void 0 : _marker$name.text,
          description: getRichText(marker === null || marker === void 0 ? void 0 : marker.description),
          type: markerTypes[marker === null || marker === void 0 ? void 0 : marker.marker_type],
          longitude: marker === null || marker === void 0 ? void 0 : (_marker$geopoint = marker.geopoint) === null || _marker$geopoint === void 0 ? void 0 : _marker$geopoint.longitude,
          latitude: marker === null || marker === void 0 ? void 0 : (_marker$geopoint2 = marker.geopoint) === null || _marker$geopoint2 === void 0 ? void 0 : _marker$geopoint2.latitude
        };
      })
    });
    return acc;
  }, {});
  if (uid) return normalized[uid];
  return normalized;
};