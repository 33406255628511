require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.array.reduce");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.iterator");

require("core-js/modules/es6.object.to-string");

require("core-js/modules/es7.object.values");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.iterator");

require("core-js/modules/es6.object.to-string");

require("core-js/modules/es7.object.values");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.iterator");

require("core-js/modules/es6.object.to-string");

require("core-js/modules/es7.object.values");

var _require = require('styled-system-scale'),
    linearScale = _require.linearScale;

var _require2 = require('@walltowall/hex-to-p3'),
    hexToP3 = _require2.hexToP3;

var sizes = {
  small: '48rem',
  medium: '60rem',
  large: '80rem',
  xlarge: '120rem'
};
var breakpoints = Object.values(sizes); // Used for linearScale count

var count = breakpoints.length + 1;
var theme = {
  /***
   * Sizes
   */
  breakpoints: breakpoints,
  sizes: sizes,

  /***
   * Colors
   */
  colors: {
    blue: {
      40: hexToP3('#00a7e1')
    },
    orange: {
      50: hexToP3('#f7510c')
    },
    green: {
      30: hexToP3('#bda600')
    },
    yellow: {
      40: hexToP3('#f5a800')
    },
    pink: {
      40: hexToP3('#c92c99')
    },
    brown: {
      30: hexToP3('#684f40')
    },
    gray: {
      10: hexToP3('#1c1c1c'),
      70: hexToP3('#b2b2b2'),
      '70transparent': hexToP3('#b2b2b200'),
      90: hexToP3('#f2f2f2')
    },
    white: '#fff',
    black: '#000'
  },

  /***
   * Fonts
   */
  fonts: {
    sans: '"Helvetica Neue", Helvetica, sans-serif',
    sansExtended: '"Helvetica Neue Extended", "Helvetica Neue", Helvetica, sans-serif'
  },
  fontSizeScales: {
    's-': linearScale('10px', '12px', {
      count: count
    }),
    s: linearScale('12px', '14px', {
      count: count
    }),
    b: linearScale('14px', '18px', {
      count: count
    }),
    'm-': linearScale('16px', '24px', {
      count: count
    }),
    m: linearScale('17px', '28px', {
      count: count
    }),
    'm+': linearScale('18px', '32px', {
      count: count
    }),
    l: linearScale('20px', '36px', {
      count: count
    }),
    'l+': linearScale('26px', '44px', {
      count: count
    }),
    'xl-': linearScale('26px', '50px', {
      count: count
    }),
    xl: linearScale('26px', '56px', {
      count: count
    }),
    xxl: linearScale('36px', '80px', {
      count: count
    }),
    h: linearScale('100px', '200px', {
      count: count
    })
  },
  fontWeights: {
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    heavy: 800
  },
  lineHeights: {
    solid: 1,
    title: 1.15,
    copy: 1.33
  },

  /***
   * Spacing
   */
  spaceScales: {
    0: linearScale(0, 0, {
      count: count
    }),
    't-': linearScale('5px', '8px', {
      count: count
    }),
    t: linearScale('10px', '12px', {
      count: count
    }),
    s: linearScale('14px', '17px', {
      count: count
    }),
    's+': linearScale('14px', '20px', {
      count: count
    }),
    'm-': linearScale('14px', '28px', {
      count: count
    }),
    m: linearScale('16px', '32px', {
      count: count
    }),
    l: linearScale('18px', '40px', {
      count: count
    }),
    'l+': linearScale('18px', '45px', {
      count: count
    }),
    xl: linearScale('20px', '50px', {
      count: count
    }),
    'xl+': linearScale('20px', '80px', {
      count: count
    }),
    xxl: linearScale('36px', '90px', {
      count: count
    }),
    h: linearScale('60px', '188px', {
      count: count
    })
  },

  /***
   * Z-Indicies
   */
  zIndices: {
    header: 2,
    slidingNav: 2,
    overlay: 2,
    menuButton: 2,
    heroGradient: 1,
    mapMarker: 1,
    floatingNavigation: 1
  },

  /***
   * Transitions
   */
  transitionDurations: {
    slow: '300ms',
    normal: '200ms',
    fast: '100ms'
  },

  /***
   * Variants
   */
  textStyles: {
    caps: {
      textTransform: 'uppercase'
    },
    trackedCaps: {
      textTransform: 'uppercase',
      letterSpacing: '0.075em'
    },
    lowercase: {
      textTransform: 'lowercase'
    }
  },
  boxStyles: {
    lastNoMargin: {
      '&:last-child': {
        marginBottom: 0,
        marginRight: 0
      }
    },
    firstLastNoMargin: {
      '&:first-child': {
        marginTop: 0,
        marginLeft: 0
      },
      '&:last-child': {
        marginBottom: 0,
        marginRight: 0
      }
    }
  },

  /***
   * Media queries
   */
  mediaQueries: Object.keys(sizes).reduce(function (acc, key) {
    acc[key] = "@media screen and (min-width: " + sizes[key] + ")";
    return acc;
  }, {})
};
exports.theme = theme;