import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { Location } from '@reach/router';
import { themeGet } from '@styled-system/theme-get';
import { isPathActiveExact } from 'helpers';
import { Box, Link } from 'system';

var _StyledLink = _styled(Link).withConfig({
  displayName: "NavigationItem___StyledLink",
  componentId: "m10tpq-0"
})(["&:hover,&:focus{color:", ";}"], function (p) {
  return p._css;
});

export var NavigationItem = function NavigationItem(_ref) {
  var children = _ref.children,
      href = _ref.href,
      target = _ref.target,
      props = _objectWithoutPropertiesLoose(_ref, ["children", "href", "target"]);

  return React.createElement(Location, null, function (_ref2) {
    var location = _ref2.location;
    return React.createElement(Box, Object.assign({
      as: "li",
      width: [1, 1 / 2, 'auto'],
      pbScale: ['t', null, 0],
      prScale: [0, 'l']
    }, props), React.createElement(_StyledLink, {
      href: href,
      target: target,
      fontSizeScale: "m",
      fontWeight: isPathActiveExact(href, location) ? 'bold' : 'light',
      outline: "0",
      transitionProperty: "color",
      lineHeight: "title",
      _css: themeGet('colors.blue.40')
    }, children));
  });
};