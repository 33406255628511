import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";
import _styled from "styled-components";
import React from 'react';
import { Link } from 'system';

var _StyledLink = _styled(Link).withConfig({
  displayName: "Anchor___StyledLink",
  componentId: "sc-7xwcie-0"
})(["", ""], function (p) {
  return {
    '&:hover, &:focus': {
      color: p.theme.colors.green[30]
    }
  };
});

export var Anchor = function Anchor(props) {
  return React.createElement(_StyledLink, Object.assign({
    color: "blue.40",
    transitionProperty: "color",
    outline: "0"
  }, props));
};