import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import _regeneratorRuntime from "@babel/runtime/regenerator";
import "regenerator-runtime/runtime";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { linearScale } from 'styled-system-scale';
import { a, config, useSpring } from 'react-spring';
import delay from 'delay';
import { Box, Button } from 'system';

var Bar = function Bar(props) {
  return React.createElement(Box, Object.assign({
    as: a(Box),
    bg: "currentColor",
    height: ['1px', null, null, '2px'],
    position: "absolute",
    width: "100%"
  }, props));
};

export var HamburgerButton = function HamburgerButton(_ref) {
  var isOpen = _ref.isOpen,
      props = _objectWithoutPropertiesLoose(_ref, ["isOpen"]);

  var topSpring = useSpring({
    from: {
      y: "translateY(" + (isOpen ? 45 : 0) + "%)",
      rot: "rotate(" + (isOpen ? 45 : 0) + "deg)"
    },
    to: function to(next) {
      return _regeneratorRuntime.async(function to$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (!isOpen) {
                _context.next = 7;
                break;
              }

              next({
                y: "translateY(" + (isOpen ? 45 : 0) + "%)"
              });
              _context.next = 4;
              return _regeneratorRuntime.awrap(delay(200));

            case 4:
              next({
                rot: "rotate(" + (isOpen ? 45 : 0) + "deg)"
              });
              _context.next = 11;
              break;

            case 7:
              next({
                rot: "rotate(" + (isOpen ? 45 : 0) + "deg)"
              });
              _context.next = 10;
              return _regeneratorRuntime.awrap(delay(200));

            case 10:
              next({
                y: "translateY(" + (isOpen ? 45 : 0) + "%)"
              });

            case 11:
            case "end":
              return _context.stop();
          }
        }
      });
    },
    config: config.stiff
  }); // const middleSpring = useSpring({
  //   from: {
  //     scale: 'scaleY(1)',
  //   },
  //   to: async next => {
  //     if (isOpen) {
  //       next({ scale: 'scaleY(0)' })
  //     } else {
  //       await delay(200)
  //       next({ scale: 'scaleY(1)' })
  //     }
  //   },
  //   config: config.stiff,
  // })

  var bottomSpring = useSpring({
    from: {
      y: "translateY(" + (isOpen ? -45 : 0) + "%)",
      rot: "rotate(" + (isOpen ? -45 : 0) + "deg)"
    },
    to: function to(next) {
      return _regeneratorRuntime.async(function to$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (!isOpen) {
                _context2.next = 7;
                break;
              }

              next({
                y: "translateY(" + (isOpen ? -45 : 0) + "%)"
              });
              _context2.next = 4;
              return _regeneratorRuntime.awrap(delay(200));

            case 4:
              next({
                rot: "rotate(" + (isOpen ? -45 : 0) + "deg)"
              });
              _context2.next = 11;
              break;

            case 7:
              next({
                rot: "rotate(" + (isOpen ? -45 : 0) + "deg)"
              });
              _context2.next = 10;
              return _regeneratorRuntime.awrap(delay(200));

            case 10:
              next({
                y: "translateY(" + (isOpen ? -45 : 0) + "%)"
              });

            case 11:
            case "end":
              return _context2.stop();
          }
        }
      });
    },
    config: config.stiff
  });
  return React.createElement(Button, Object.assign({
    width: linearScale('22px', '30px', {
      count: 5
    }),
    height: linearScale('12px', '18px', {
      count: 5
    }),
    position: "relative",
    outline: "none"
  }, props), React.createElement(Box, {
    as: a(Box),
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    style: {
      transform: topSpring.y
    }
  }, React.createElement(Bar, {
    left: 0,
    top: 0,
    style: {
      transform: topSpring.rot
    }
  })), React.createElement(Box, {
    as: a(Box),
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    style: {
      transform: bottomSpring.y
    }
  }, React.createElement(Bar, {
    bottom: 0,
    left: 0,
    style: {
      transform: bottomSpring.rot
    }
  })));
};