import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { notEmpty } from 'helpers';
import { Box, ImageContainer, Image, AspectRatio, Text } from 'system';
import { Heading, Subheading } from 'src/components';
export var TeamMemberCard = function TeamMemberCard(_ref) {
  var name = _ref.name,
      title = _ref.title,
      _ref$certifications = _ref.certifications,
      certifications = _ref$certifications === void 0 ? [] : _ref$certifications,
      tweet = _ref.tweet,
      imageFluid = _ref.imageFluid,
      imageAlt = _ref.imageAlt,
      imageURL = _ref.imageURL,
      props = _objectWithoutPropertiesLoose(_ref, ["name", "title", "certifications", "tweet", "imageFluid", "imageAlt", "imageURL"]);

  return React.createElement(Box, props, (imageFluid || imageURL) && React.createElement(ImageContainer, {
    mbScale: "m",
    bg: "gray.10"
  }, React.createElement(AspectRatio, {
    x: 3,
    y: 3.5
  }, React.createElement(Image, {
    fluid: imageFluid,
    src: imageURL,
    alt: imageAlt
  }))), name && React.createElement(Heading, {
    as: "h4",
    color: "blue.40",
    mbScale: "t",
    fontSizeScale: ['l+', null, 'm', 'l'],
    fontWeight: "light"
  }, name), notEmpty(certifications) && React.createElement(Subheading, {
    as: "p",
    color: "gray.70",
    mbScale: "t-"
  }, certifications.join(', ')), title && React.createElement(Text, {
    as: "p",
    fontSizeScale: ['m', null, 'm-', 'm'],
    fontWeight: "light"
  }, title), tweet && React.createElement(Text, {
    as: "p",
    mtScale: "s"
  }, tweet));
};