import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import HTMLRenderer from 'react-html-renderer';
import { Box, Text } from 'system';
import { Heading, Subheading, Anchor } from 'src/components';
var baseHeadingProps = {
  boxStyle: 'firstLastNoMargin',
  mbScale: 's',
  mtScale: 's'
};
var baseTextProps = {
  boxStyle: 'lastNoMargin',
  mbScale: 's'
};

var _StyledBox = _styled(Box).withConfig({
  displayName: "HTMLContent___StyledBox",
  componentId: "sc-266lbk-0"
})({
  listStyle: 'disc'
});

var _StyledBox2 = _styled(Box).withConfig({
  displayName: "HTMLContent___StyledBox2",
  componentId: "sc-266lbk-1"
})({
  listStyle: 'decimal'
});

var defaultComponents = {
  h1: function h1(props) {
    return React.createElement(Heading, Object.assign({
      as: "h3",
      lineHeight: "title",
      fontWeight: "thin",
      fontFamily: "sansExtended"
    }, baseHeadingProps, props));
  },
  h2: function h2(props) {
    return React.createElement(Subheading, Object.assign({
      as: "h4"
    }, baseHeadingProps, props));
  },
  h3: function h3(props) {
    return React.createElement(Subheading, Object.assign({
      as: "h5"
    }, baseHeadingProps, props));
  },
  h4: function h4(props) {
    return React.createElement(Subheading, Object.assign({
      as: "h6"
    }, baseHeadingProps, props));
  },
  h5: function h5(props) {
    return React.createElement(Subheading, Object.assign({
      as: "h6"
    }, baseHeadingProps, props));
  },
  h6: function h6(props) {
    return React.createElement(Subheading, Object.assign({
      as: "h6"
    }, baseHeadingProps, props));
  },
  p: function p(props) {
    return React.createElement(Text, Object.assign({
      as: "p"
    }, baseTextProps, props));
  },
  ul: function ul(props) {
    return React.createElement(_StyledBox, Object.assign({
      as: "ul",
      plScale: "t"
    }, baseTextProps, props));
  },
  ol: function ol(props) {
    return React.createElement(_StyledBox2, Object.assign({
      as: "ul",
      plScale: "t"
    }, baseTextProps, props));
  },
  li: function li(props) {
    return React.createElement(Text, Object.assign({
      boxStyle: "lastNoMargin",
      as: "li",
      ml: "1rem",
      display: "list-item",
      mbScale: "t-"
    }, props));
  },
  a: function a(props) {
    return React.createElement(Anchor, props);
  }
};
export var HTMLContent = function HTMLContent(_ref) {
  var html = _ref.html,
      componentOverrides = _ref.componentOverrides,
      props = _objectWithoutPropertiesLoose(_ref, ["html", "componentOverrides"]);

  return React.createElement(Text, Object.assign({
    lineHeight: "copy"
  }, props), html && React.createElement(HTMLRenderer, {
    html: html,
    components: defaultComponents,
    componentOverrides: componentOverrides
  }));
};