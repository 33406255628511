import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import ConditionalWrap from 'conditional-wrap';
import { Link, Text } from 'system';
import { Subheading } from 'src/components';

var _StyledLink = _styled(Link).withConfig({
  displayName: "CategoryBreadCrumb___StyledLink",
  componentId: "sc-1kmoaro-0"
})(["", ""], function (p) {
  return {
    '&:hover, &:focus': {
      color: p.theme.colors.blue[40]
    }
  };
});

var _StyledLink2 = _styled(Link).withConfig({
  displayName: "CategoryBreadCrumb___StyledLink2",
  componentId: "sc-1kmoaro-1"
})(["", ""], function (p) {
  return {
    '&:hover, &:focus': {
      color: p.theme.colors.blue[40]
    }
  };
});

export var CategoryBreadCrumb = function CategoryBreadCrumb(_ref) {
  var categoryName = _ref.categoryName,
      categoryHref = _ref.categoryHref,
      parentName = _ref.parentName,
      parentHref = _ref.parentHref,
      color = _ref.color,
      props = _objectWithoutPropertiesLoose(_ref, ["categoryName", "categoryHref", "parentName", "parentHref", "color"]);

  return React.createElement(Subheading, Object.assign({
    as: "p",
    color: "gray.70"
  }, props), parentName && React.createElement(ConditionalWrap, {
    condition: parentHref,
    wrap: function wrap(children) {
      return React.createElement(_StyledLink, {
        href: parentHref,
        transitionProperty: "color"
      }, children);
    }
  }, parentName), parentName && categoryName && React.createElement(Text, {
    color: "gray.70",
    display: "inline-block",
    mxScale: "t"
  }, "/"), categoryName && React.createElement(ConditionalWrap, {
    condition: categoryHref,
    wrap: function wrap(children) {
      return React.createElement(_StyledLink2, {
        href: categoryHref,
        color: color,
        transitionProperty: "color"
      }, children);
    }
  }, categoryName));
};