import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useState } from 'react';
import { useGesture } from 'react-use-gesture';
import { useNewsCategoriesData } from 'src/hooks';
import { Box, Grid, ImageContainer, Image, AspectRatio, Text, Link } from 'system';
import { Heading, Subheading } from 'src/components';

var _StyledLink = _styled(Link).withConfig({
  displayName: "NewsCard___StyledLink",
  componentId: "sc-14jyp36-0"
})(["", ""], function (p) {
  return {
    '&:hover, &:focus': {
      color: p.theme.colors.blue[40]
    }
  };
});

export var NewsCard = function NewsCard(_ref) {
  var _category$data, _category$data$title;

  var href = _ref.href,
      title = _ref.title,
      categoryUID = _ref.categoryUID,
      date = _ref.date,
      imageFluid = _ref.imageFluid,
      imageURL = _ref.imageURL,
      imageAlt = _ref.imageAlt,
      props = _objectWithoutPropertiesLoose(_ref, ["href", "title", "categoryUID", "date", "imageFluid", "imageURL", "imageAlt"]);

  var _useState = useState(false),
      isFocused = _useState[0],
      setIsFocused = _useState[1];

  var handleFocus = function handleFocus(isFocused) {
    return setIsFocused(function () {
      return Boolean(isFocused);
    });
  };

  var bind = useGesture({
    onHover: function onHover(_ref2) {
      var hovering = _ref2.hovering;
      return handleFocus(hovering);
    },
    onDrag: function onDrag(_ref3) {
      var down = _ref3.down;
      return handleFocus(down);
    }
  });
  var category = useNewsCategoriesData(categoryUID);
  return React.createElement(Grid, Object.assign({
    gridTemplateColumns: ['repeat(6, 1fr)', '1fr'],
    gridGapScale: "l",
    bg: "gray.90",
    pScale: "m",
    placeContent: "start"
  }, props), React.createElement(Link, Object.assign({
    href: href,
    tabIndex: "-1",
    display: "block",
    outline: "0",
    gridColumn: ['span 2', '1 / -1'],
    mxScale: [0, '-m'],
    mtScale: [0, '-m']
  }, bind()), React.createElement(ImageContainer, {
    height: "100%",
    bg: "gray.10"
  }, React.createElement(AspectRatio, {
    x: 3,
    y: 4
  }, (imageFluid || imageURL) && React.createElement(Image, {
    fluid: imageFluid,
    src: imageURL,
    alt: imageAlt
  })))), React.createElement(Box, {
    gridColumn: ['span 4', '1 / -1']
  }, category && React.createElement(Subheading, {
    color: "green.30",
    mbScale: "s"
  }, React.createElement(_StyledLink, {
    href: category === null || category === void 0 ? void 0 : category.url,
    transitionProperty: "color"
  }, category === null || category === void 0 ? void 0 : (_category$data = category.data) === null || _category$data === void 0 ? void 0 : (_category$data$title = _category$data.title) === null || _category$data$title === void 0 ? void 0 : _category$data$title.text)), title && React.createElement(Heading, {
    fontWeight: "light",
    fontSizeScale: ['l', null, 'm', null, 'l'],
    mbScale: "s"
  }, React.createElement(Link, Object.assign({
    href: href,
    onBlur: function onBlur() {
      return handleFocus(false);
    },
    onFocus: function onFocus() {
      return handleFocus(true);
    },
    color: isFocused ? 'blue.40' : 'inherit',
    outline: "0",
    transitionProperty: "color"
  }, bind()), title)), date && React.createElement(Text, {
    as: "p"
  }, date)));
};