import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { linearScale } from 'styled-system-scale';
import { themeGet } from '@styled-system/theme-get';
import { Text, Link, SVG } from 'system';
import { ReactComponent as AssetIconArrowUpSVG } from 'src/assets/icon-arrow-up.svg';

var _StyledLink = _styled(Link).withConfig({
  displayName: "BackToTopButton___StyledLink",
  componentId: "rwbded-0"
})(["&:hover,&:focus{color:", ";}"], function (p) {
  return p._css;
});

export var BackToTopButton = function BackToTopButton(_ref) {
  var _ref$children = _ref.children,
      children = _ref$children === void 0 ? 'Back to top' : _ref$children,
      props = _objectWithoutPropertiesLoose(_ref, ["children"]);

  return React.createElement(_StyledLink, Object.assign({
    href: "#top",
    display: "inline-flex",
    alignItems: "center",
    transitionProperty: "color",
    outline: "0"
  }, props, {
    _css: themeGet('colors.blue.40')
  }), React.createElement(Text, {
    mrScale: "t",
    fontSizeScale: "m",
    fontWeight: "light",
    lineHeight: "solid"
  }, children), React.createElement(SVG, {
    svg: AssetIconArrowUpSVG,
    x: 24,
    y: 21,
    width: linearScale('16px', '24px', {
      count: 5
    }),
    color: "currentColor"
  }));
};