import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { BoundedBox as BoundedBoxBase } from 'system';
export var BoundedBox = React.forwardRef(function (_ref, ref) {
  var _nextSharesBg$map;

  var _ref$nextSharesBg = _ref.nextSharesBg,
      nextSharesBg = _ref$nextSharesBg === void 0 ? [false] : _ref$nextSharesBg,
      props = _objectWithoutPropertiesLoose(_ref, ["nextSharesBg"]);

  return React.createElement(BoundedBoxBase, Object.assign({
    ref: ref,
    pxScale: "xl",
    ptScale: "l+",
    pbScale: nextSharesBg === null || nextSharesBg === void 0 ? void 0 : (_nextSharesBg$map = nextSharesBg.map) === null || _nextSharesBg$map === void 0 ? void 0 : _nextSharesBg$map.call(nextSharesBg, function (cond) {
      return cond ? 0 : 'l+';
    }),
    maxWidth: "none"
  }, props));
});