import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _regeneratorRuntime from "@babel/runtime/regenerator";
import "regenerator-runtime/runtime";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useEffect } from 'react';
import delay from 'delay';
import { useSpring, a } from 'react-spring';
import { Box } from 'system';

var _StyledBox = _styled(Box).withConfig({
  displayName: "Overlay___StyledBox",
  componentId: "m8ynso-0"
})(function (p) {
  return {
    pointerEvents: p._css
  };
});

export var Overlay = function Overlay(_ref) {
  var isActive = _ref.isActive,
      props = _objectWithoutPropertiesLoose(_ref, ["isActive"]);

  var _useSpring = useSpring(function () {
    return {
      opacity: 0
    };
  }),
      style = _useSpring[0],
      set = _useSpring[1];

  useEffect(function () {
    var asyncAnim = function asyncAnim() {
      return _regeneratorRuntime.async(function asyncAnim$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (isActive) {
                _context.next = 3;
                break;
              }

              _context.next = 3;
              return _regeneratorRuntime.awrap(delay(350));

            case 3:
              set({
                opacity: isActive ? 0.7 : 0
              });

            case 4:
            case "end":
              return _context.stop();
          }
        }
      });
    };

    asyncAnim();
  }, [isActive, set]);
  return React.createElement(_StyledBox, Object.assign({
    as: a(Box),
    style: style,
    "aria-hidden": !isActive,
    bg: "gray.10",
    height: "100vh",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: "overlay"
  }, props, {
    _css: isActive ? 'auto' : 'none'
  }));
};