import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";
import React from 'react';
import { Heading } from 'system';
export var Subheading = function Subheading(props) {
  return React.createElement(Heading, Object.assign({
    as: "h3",
    fontSizeScale: ['s', null, null, 'b'],
    fontWeight: "heavy",
    lineHeight: "title",
    textStyle: "trackedCaps"
  }, props));
};