import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.reduce";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";
import staticQueryData from "../../public/static/d/3291354490.json";
import { camelCase } from 'tiny-compose-fns';
import { BRAND_COLORS } from 'src/constants';
export var useProjectCategoriesData = function useProjectCategoriesData(uid) {
  var _queryData$allPrismic, _queryData$allPrismic2, _queryData$allPrismic3;

  var queryData = staticQueryData.data;
  var normalized = queryData === null || queryData === void 0 ? void 0 : (_queryData$allPrismic = queryData.allPrismicProjectCategory) === null || _queryData$allPrismic === void 0 ? void 0 : (_queryData$allPrismic2 = _queryData$allPrismic.nodes) === null || _queryData$allPrismic2 === void 0 ? void 0 : (_queryData$allPrismic3 = _queryData$allPrismic2.reduce) === null || _queryData$allPrismic3 === void 0 ? void 0 : _queryData$allPrismic3.call(_queryData$allPrismic2, function (acc, category) {
    var _category$data, _category$data$title, _ref, _category$data2, _category$data3;

    acc[category === null || category === void 0 ? void 0 : category.uid] = Object.assign({}, category, {
      title: category === null || category === void 0 ? void 0 : (_category$data = category.data) === null || _category$data === void 0 ? void 0 : (_category$data$title = _category$data.title) === null || _category$data$title === void 0 ? void 0 : _category$data$title.text,
      color: (_ref = BRAND_COLORS === null || BRAND_COLORS === void 0 ? void 0 : BRAND_COLORS[camelCase(category === null || category === void 0 ? void 0 : (_category$data2 = category.data) === null || _category$data2 === void 0 ? void 0 : _category$data2.color)]) !== null && _ref !== void 0 ? _ref : camelCase(category === null || category === void 0 ? void 0 : (_category$data3 = category.data) === null || _category$data3 === void 0 ? void 0 : _category$data3.color)
    });
    return acc;
  }, {});
  if (uid) return normalized === null || normalized === void 0 ? void 0 : normalized[uid];
  return normalized;
};