import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.array.sort";
import staticQueryData from "../../public/static/d/1114417977.json";
import { getMetadata } from 'helpers';
export var useNewsPagination = function useNewsPagination(uid) {
  var _queryData$allPrismic, _queryData$allPrismic2, _queryData$allPrismic3;

  var queryData = staticQueryData.data;
  var nodes = queryData === null || queryData === void 0 ? void 0 : (_queryData$allPrismic = queryData.allPrismicPage) === null || _queryData$allPrismic === void 0 ? void 0 : (_queryData$allPrismic2 = _queryData$allPrismic.nodes) === null || _queryData$allPrismic2 === void 0 ? void 0 : (_queryData$allPrismic3 = _queryData$allPrismic2.sort) === null || _queryData$allPrismic3 === void 0 ? void 0 : _queryData$allPrismic3.call(_queryData$allPrismic2, function (a, b) {
    var _getMetadata, _getMetadata$primary, _getMetadata2, _getMetadata2$primary;

    var dateA = (_getMetadata = getMetadata('PrismicPageMainNews', a)) === null || _getMetadata === void 0 ? void 0 : (_getMetadata$primary = _getMetadata.primary) === null || _getMetadata$primary === void 0 ? void 0 : _getMetadata$primary.date;
    var dateB = (_getMetadata2 = getMetadata('PrismicPageMainNews', b)) === null || _getMetadata2 === void 0 ? void 0 : (_getMetadata2$primary = _getMetadata2.primary) === null || _getMetadata2$primary === void 0 ? void 0 : _getMetadata2$primary.date;
    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  });
  var thisNodeIndex = nodes.findIndex(function (node) {
    return node.uid === uid;
  });
  if (thisNodeIndex < 0) return {};
  var prev = nodes[thisNodeIndex - 1];
  var next = nodes[thisNodeIndex + 1];
  return {
    prev: prev,
    next: next
  };
};