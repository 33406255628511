import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _regeneratorRuntime from "@babel/runtime/regenerator";
import "regenerator-runtime/runtime";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { useSpring, a } from 'react-spring';
import { useGesture } from 'react-use-gesture';
import { theme } from 'src/theme';
import { Box, SVG, Button as ButtonBase } from 'system';
import { ReactComponent as AssetIconArrowRightSVG } from 'src/assets/icon-arrow-right.svg';
import { ReactComponent as AssetIconArrowDownSVG } from 'src/assets/icon-arrow-down.svg';
var colorSchemes = {
  light: {
    borderColor: theme.colors.green[30],
    textColor: theme.colors.white,
    arrowColor: theme.colors.green[30],
    focusedBgColor: theme.colors.green[30],
    focusedBorderColor: theme.colors.green[30],
    focusedTextColor: theme.colors.white,
    focusedArrowColor: theme.colors.white
  },
  dark: {
    borderColor: theme.colors.green[30],
    textColor: theme.colors.gray[10],
    arrowColor: theme.colors.green[30],
    focusedBgColor: theme.colors.green[30],
    focusedBorderColor: theme.colors.green[30],
    focusedTextColor: theme.colors.white,
    focusedArrowColor: theme.colors.white
  }
};
var arrows = {
  right: {
    svg: AssetIconArrowRightSVG,
    x: 21,
    y: 23
  },
  down: {
    svg: AssetIconArrowDownSVG,
    x: 24,
    y: 21
  }
};

var _StyledButtonBase = _styled(ButtonBase).withConfig({
  displayName: "Button___StyledButtonBase",
  componentId: "m8hwv0-0"
})({
  whiteSpace: 'nowrap'
});

var _StyledBox = _styled(Box).withConfig({
  displayName: "Button___StyledBox",
  componentId: "m8hwv0-1"
})({
  pointerEvents: 'none'
});

export var Button = function Button(_ref) {
  var children = _ref.children,
      _ref$withArrow = _ref.withArrow,
      withArrow = _ref$withArrow === void 0 ? true : _ref$withArrow,
      _ref$arrowDirection = _ref.arrowDirection,
      arrowDirection = _ref$arrowDirection === void 0 ? 'right' : _ref$arrowDirection,
      _ref$variant = _ref.variant,
      variantName = _ref$variant === void 0 ? 'dark' : _ref$variant,
      props = _objectWithoutPropertiesLoose(_ref, ["children", "withArrow", "arrowDirection", "variant"]);

  var variant = colorSchemes[variantName];
  var arrow = arrows[arrowDirection];

  var _useSpring = useSpring(function () {
    return {
      borderColor: variant === null || variant === void 0 ? void 0 : variant.borderColor,
      color: variant === null || variant === void 0 ? void 0 : variant.textColor,
      arrowColor: variant === null || variant === void 0 ? void 0 : variant.arrowColor,
      opacity: 0,
      transform: 'translateX(-25%)'
    };
  }),
      _useSpring$ = _useSpring[0],
      color = _useSpring$.color,
      borderColor = _useSpring$.borderColor,
      opacity = _useSpring$.opacity,
      transform = _useSpring$.transform,
      arrowColor = _useSpring$.arrowColor,
      set = _useSpring[1];

  var handleFocus = function handleFocus(isFocused) {
    return _regeneratorRuntime.async(function handleFocus$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            if (isFocused) {
              set({
                borderColor: variant === null || variant === void 0 ? void 0 : variant.focusedBorderColor,
                color: variant === null || variant === void 0 ? void 0 : variant.focusedTextColor,
                arrowColor: variant === null || variant === void 0 ? void 0 : variant.focusedArrowColor,
                opacity: 1,
                transform: 'translateX(0%)'
              });
            } else {
              set({
                borderColor: variant === null || variant === void 0 ? void 0 : variant.borderColor,
                color: variant === null || variant === void 0 ? void 0 : variant.textColor,
                arrowColor: variant === null || variant === void 0 ? void 0 : variant.arrowColor,
                opacity: 0,
                transform: 'translateX(-25%)'
              });
            }

          case 1:
          case "end":
            return _context.stop();
        }
      }
    });
  };

  var bind = useGesture({
    onHover: function onHover(_ref2) {
      var hovering = _ref2.hovering;
      return handleFocus(hovering);
    },
    onDrag: function onDrag(_ref3) {
      var down = _ref3.down;
      return handleFocus(down);
    }
  });
  return React.createElement(_StyledButtonBase, Object.assign({
    as: a(ButtonBase),
    onBlur: function onBlur() {
      return handleFocus(false);
    },
    onFocus: function onFocus() {
      return handleFocus(true);
    },
    alignItems: "center",
    border: "1px solid",
    display: "inline-flex",
    fontSizeScale: "m-",
    fontWeight: "light",
    justifyContent: "space-between",
    lineHeight: "solid",
    overflow: "hidden",
    pScale: "s",
    position: "relative",
    outline: "0",
    style: {
      color: color,
      borderColor: borderColor
    }
  }, bind(), props), React.createElement(_StyledBox, {
    as: a.div,
    bg: variant === null || variant === void 0 ? void 0 : variant.focusedBgColor,
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    style: {
      opacity: opacity,
      transform: transform
    }
  }), React.createElement(Box, {
    position: "relative"
  }, children), withArrow && arrow && React.createElement(Box, {
    as: a.div,
    mlScale: "xl",
    width: ['1rem', '1.2rem'],
    style: {
      color: color
    }
  }, React.createElement(Box, {
    as: a.div,
    style: {
      color: arrowColor
    }
  }, React.createElement(SVG, {
    svg: arrow.svg,
    width: "100%",
    x: arrow.x,
    y: arrow.y,
    color: "inherit"
  }))));
};