import { useRef, useCallback } from 'react';
import { isBrowser } from 'helpers';
export var useScrollToBottom = function useScrollToBottom() {
  var ref = useRef();
  var scroll = useCallback(function () {
    var el = ref.current;
    if (!el || !isBrowser) return;
    var rect = el.getBoundingClientRect();
    window.scrollTo({
      top: rect.bottom,
      behavior: 'smooth'
    });
  }, []);
  return [ref, scroll];
};