import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { a } from 'react-spring';
import { linearScale } from 'styled-system-scale';
import { Box, Flex, Link, Image, ImageContainer } from 'system';
import { HTMLContent, Logo, Button } from 'src/components';
var variants = {
  light: {
    color: 'gray.10',
    bg: 'gray.90',
    buttonVariant: 'dark',
    logoScheme: 'dark',
    imageOpacity: 0.15
  },
  dark: {
    color: 'white',
    bg: 'brown.30',
    buttonVariant: 'light',
    logoScheme: 'light',
    imageOpacity: 1
  }
};

var _StyledImageContainer = _styled(ImageContainer).withConfig({
  displayName: "BrandedCallout___StyledImageContainer",
  componentId: "sc-186g10g-0"
})({
  mixBlendMode: 'multiply'
});

var _StyledImage = _styled(Image).withConfig({
  displayName: "BrandedCallout___StyledImage",
  componentId: "sc-186g10g-1"
})({
  pointerEvents: 'none',
  filter: 'grayscale(100%)'
});

export var BrandedCallout = React.memo(function (_ref) {
  var textHTML = _ref.textHTML,
      _ref$variant = _ref.variant,
      variantName = _ref$variant === void 0 ? 'light' : _ref$variant,
      buttonText = _ref.buttonText,
      buttonHref = _ref.buttonHref,
      buttonTarget = _ref.buttonTarget,
      _ref$hideLogoOnMobile = _ref.hideLogoOnMobile,
      hideLogoOnMobile = _ref$hideLogoOnMobile === void 0 ? true : _ref$hideLogoOnMobile,
      _ref$textWidth = _ref.textWidth,
      textWidth = _ref$textWidth === void 0 ? [1, 3 / 4, null, 1 / 2] : _ref$textWidth,
      backgroundImageUrl = _ref.backgroundImageUrl,
      backgroundImageAlt = _ref.backgroundImageAlt,
      backgroundImageFluid = _ref.backgroundImageFluid,
      props = _objectWithoutPropertiesLoose(_ref, ["textHTML", "variant", "buttonText", "buttonHref", "buttonTarget", "hideLogoOnMobile", "textWidth", "backgroundImageUrl", "backgroundImageAlt", "backgroundImageFluid"]);

  var variant = variants[variantName];
  return React.createElement(Flex, Object.assign({
    bg: variant.bg,
    pxScale: "xl",
    pyScale: "l+",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative"
  }, props), (backgroundImageUrl || backgroundImageFluid) && React.createElement(_StyledImageContainer, {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: "0",
    left: "0",
    opacity: variant.imageOpacity
  }, React.createElement(_StyledImage, {
    src: backgroundImageUrl,
    alt: backgroundImageAlt,
    fluid: backgroundImageFluid
  })), React.createElement(HTMLContent, {
    html: textHTML,
    color: variant.color,
    mbScale: "h",
    position: "relative",
    width: textWidth,
    componentOverrides: {
      h1: function h1(Comp) {
        return function (props) {
          return React.createElement(Comp, Object.assign({}, props, {
            fontSizeScale: "l+"
          }));
        };
      }
    }
  }), React.createElement(Flex, {
    justifyContent: "space-between",
    alignItems: "center"
  }, buttonHref ? React.createElement(Button, {
    as: a(Link),
    href: buttonHref,
    target: buttonTarget,
    mrScale: "m",
    variant: variant.buttonVariant
  }, buttonText) : React.createElement(Box, null), React.createElement(Logo, {
    colorScheme: variant.logoScheme,
    display: hideLogoOnMobile ? ['none', 'block'] : 'block',
    width: linearScale('70px', '175px', {
      count: 5
    })
  })));
});