import "core-js/modules/es6.string.starts-with";
import "core-js/modules/es6.string.starts-with";
import "core-js/modules/es6.string.starts-with";
import React from 'react';
import { PageWrapper } from 'src/components';
export var wrapPageElement = function wrapPageElement(_ref) {
  var element = _ref.element,
      props = _ref.props;
  var location = props.location;
  if (location.pathname.startsWith('/admin/')) return element;
  return React.createElement(PageWrapper, null, element);
};