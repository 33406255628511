import { useEffect } from 'react';
export var useKeyPress = function useKeyPress(targetKey, callback) {
  var keyHandler = function keyHandler(event) {
    if (event.key === targetKey) callback();
  };

  useEffect(function () {
    window.addEventListener('keydown', keyHandler);
    return function () {
      return void window.removeEventListener('keydown', keyHandler);
    };
  }, []);
};