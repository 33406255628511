import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";
import React from 'react';
import { Heading as HeadingBase } from 'system';
export var Heading = function Heading(props) {
  return React.createElement(HeadingBase, Object.assign({
    fontFamily: "sansExtended",
    fontSizeScale: "xl",
    fontWeight: "thin",
    lineHeight: "title"
  }, props));
};