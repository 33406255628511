import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { Location } from '@reach/router';
import { isPathActiveExact } from 'helpers';
import { Box, Link } from 'system';
import { Subheading } from 'src/components';

var _StyledLink = _styled(Link).withConfig({
  displayName: "ButtonNavigationItem___StyledLink",
  componentId: "sc-1escy3o-0"
})(["", ""], function (p) {
  return {
    '&:hover, &:focus': {
      background: p.theme.colors.green[30]
    }
  };
});

export var ButtonNavigationItem = function ButtonNavigationItem(_ref) {
  var href = _ref.href,
      target = _ref.target,
      children = _ref.children,
      props = _objectWithoutPropertiesLoose(_ref, ["href", "target", "children"]);

  return React.createElement(Location, null, function (_ref2) {
    var location = _ref2.location;
    return React.createElement(Box, Object.assign({
      as: "li",
      mrScale: "t",
      boxStyle: "lastNoMargin"
    }, props), React.createElement(_StyledLink, {
      href: href,
      target: target,
      display: "block",
      bg: isPathActiveExact(href, location) ? 'green.30' : 'brown.30',
      pyScale: "t",
      pxScale: "s",
      color: "white",
      transitionProperty: "background",
      outline: "none",
      textAlign: "center"
    }, React.createElement(Subheading, null, children)));
  });
};