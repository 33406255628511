import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.object.assign";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useState } from 'react';
import ReactMapGL, { Marker, NavigationControl, Popup } from 'react-map-gl';
import { Helmet } from 'react-helmet';
import { useMapData } from 'src/hooks';
import { Box, Button, SVG } from 'system';
import { HTMLContent, Subheading } from 'src/components';
import { ReactComponent as AssetIconMapMarkerPVASVG } from 'src/assets/map-marker-pva.svg';
import { ReactComponent as AssetIconMarkMarkerParkingSVG } from 'src/assets/map-marker-parking.svg';
import { ReactComponent as AssetIconXSVG } from 'src/assets/icon-x.svg';
var icons = {
  pvaLogo: {
    svg: AssetIconMapMarkerPVASVG,
    x: 25,
    y: 39
  },
  parking: {
    svg: AssetIconMarkMarkerParkingSVG,
    x: 25,
    y: 39
  }
};

var _StyledBox = _styled(Box).withConfig({
  displayName: "Map___StyledBox",
  componentId: "fcnzrh-0"
})({
  pointerEvents: 'none'
});

var _StyledSVG = _styled(SVG).withConfig({
  displayName: "Map___StyledSVG",
  componentId: "fcnzrh-1"
})({
  pointerEvents: 'auto'
});

var MapMarker = function MapMarker(_ref) {
  var type = _ref.type,
      longitude = _ref.longitude,
      latitude = _ref.latitude,
      onClick = _ref.onClick,
      props = _objectWithoutPropertiesLoose(_ref, ["type", "longitude", "latitude", "onClick"]);

  var icon = icons[type];
  return React.createElement(_StyledBox, Object.assign({
    as: Marker,
    longitude: longitude,
    latitude: latitude
  }, props), React.createElement(_StyledSVG, {
    svg: icon.svg,
    x: icon.x,
    y: icon.y,
    width: "3rem",
    cursor: "pointer",
    transform: "translateY(-100%)",
    onClick: onClick
  }));
};

var _StyledBox2 = _styled(Box).withConfig({
  displayName: "Map___StyledBox2",
  componentId: "fcnzrh-2"
})({
  '.mapboxgl-popup-content': {
    backgroundColor: 'transparent',
    padding: 0,
    pointerEvents: 'none'
  }
});

var _StyledButton = _styled(Button).withConfig({
  displayName: "Map___StyledButton",
  componentId: "fcnzrh-3"
})(["", ""], function (p) {
  return {
    pointerEvents: 'auto',
    '&:hover, &:focus': {
      color: p.theme.colors.blue[40]
    }
  };
});

export var Map = function Map(_ref2) {
  var _map$markers, _map$markers$map, _map$markers$map$call, _map$markers$map$call2;

  var uid = _ref2.uid,
      props = _objectWithoutPropertiesLoose(_ref2, ["uid"]);

  var map = useMapData(uid);

  var _useState = useState({
    latitude: map === null || map === void 0 ? void 0 : map.latitude,
    longitude: map === null || map === void 0 ? void 0 : map.longitude,
    pitch: 40,
    zoom: 17.5
  }),
      viewport = _useState[0],
      setViewport = _useState[1];

  var _useState2 = useState(null),
      activeMarkerIndex = _useState2[0],
      setActiveMarkerIndex = _useState2[1];

  return React.createElement(React.Fragment, null, React.createElement(Helmet, null, React.createElement("link", {
    key: "mapglcsspatch",
    rel: "stylesheet",
    href: "https://api.tiles.mapbox.com/mapbox-gl-js/v1.5.1/mapbox-gl.css",
    type: "text/css"
  })), React.createElement(ReactMapGL, Object.assign({}, viewport, {
    mapboxApiAccessToken: process.env.GATSBY_MAPBOX_ACCESS_TOKEN,
    width: "100%",
    height: "100%",
    onViewportChange: function onViewportChange(newViewport) {
      return setViewport(newViewport);
    },
    scrollZoom: false
  }, props), React.createElement(Box, {
    position: "absolute",
    top: ['1rem', '2rem'],
    right: ['1rem', '2rem']
  }, React.createElement(NavigationControl, {
    onViewportChange: function onViewportChange(newViewport) {
      return setViewport(newViewport);
    }
  })), map === null || map === void 0 ? void 0 : (_map$markers = map.markers) === null || _map$markers === void 0 ? void 0 : (_map$markers$map = _map$markers.map) === null || _map$markers$map === void 0 ? void 0 : (_map$markers$map$call = _map$markers$map.call(_map$markers, function (marker, index) {
    return React.createElement(React.Fragment, null, React.createElement(MapMarker, {
      key: marker.longitude + " " + marker.latitude,
      type: marker.type,
      longitude: marker.longitude,
      latitude: marker.latitude,
      onClick: function onClick() {
        return setActiveMarkerIndex(index);
      }
    }), (marker.name || marker.description) && React.createElement(_StyledBox2, {
      as: Popup,
      key: marker.longitude + " " + marker.latitude,
      longitude: marker.longitude,
      latitude: marker.latitude,
      closeButton: false,
      anchor: "top",
      visibility: activeMarkerIndex === index ? 'visible' : 'hidden',
      zIndex: "mapMarker"
    }, React.createElement(Box, {
      maxWidth: "60vw",
      width: "300px",
      bg: "white",
      pxScale: "m",
      pyScale: "m-",
      position: "relative"
    }, React.createElement(_StyledButton, {
      onClick: function onClick() {
        return setActiveMarkerIndex(null);
      },
      pScale: "s",
      position: "absolute",
      top: 0,
      right: 0,
      color: "green.30",
      transitionProperty: "color",
      outline: "0"
    }, React.createElement(SVG, {
      svg: AssetIconXSVG,
      x: 1,
      y: 1,
      width: "12px"
    })), marker.name && React.createElement(Subheading, {
      fontSizeScale: "s",
      mbScale: "t-",
      boxStyle: "lastNoMargin"
    }, marker.name), marker.description && React.createElement(HTMLContent, {
      html: marker.description,
      fontSizeScale: "s"
    }))));
  })) === null || _map$markers$map$call === void 0 ? void 0 : (_map$markers$map$call2 = _map$markers$map$call.reverse) === null || _map$markers$map$call2 === void 0 ? void 0 : _map$markers$map$call2.call(_map$markers$map$call)));
};