import "core-js/modules/es6.array.reduce";
import staticQueryData from "../../public/static/d/3190332981.json";
export var useNewsCategoriesData = function useNewsCategoriesData(uid) {
  var _queryData$allPrismic, _queryData$allPrismic2, _queryData$allPrismic3;

  var queryData = staticQueryData.data;
  var normalized = queryData === null || queryData === void 0 ? void 0 : (_queryData$allPrismic = queryData.allPrismicNewsCategory) === null || _queryData$allPrismic === void 0 ? void 0 : (_queryData$allPrismic2 = _queryData$allPrismic.nodes) === null || _queryData$allPrismic2 === void 0 ? void 0 : (_queryData$allPrismic3 = _queryData$allPrismic2.reduce) === null || _queryData$allPrismic3 === void 0 ? void 0 : _queryData$allPrismic3.call(_queryData$allPrismic2, function (acc, category) {
    acc[category === null || category === void 0 ? void 0 : category.uid] = category;
    return acc;
  }, {});
  if (uid) return normalized === null || normalized === void 0 ? void 0 : normalized[uid];
  return normalized;
};