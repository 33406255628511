import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useRef, useState } from 'react';
import { linearScale } from 'styled-system-scale';
import { useSpring, a } from 'react-spring';
import { themeGet } from '@styled-system/theme-get';
import { Box, Flex, Button as ButtonBase, Input, SVG } from 'system';
import { ReactComponent as AssetIconSearchSVG } from 'src/assets/icon-search.svg';
var AnimInput = a(Input);

var _StyledAnimInput = _styled(AnimInput).withConfig({
  displayName: "SearchInput___StyledAnimInput",
  componentId: "sc-1jorkwy-0"
})(["", ""], function (p) {
  return {
    '&::placeholder': {
      color: p.theme.colors.gray[70],
      textTransform: 'lowercase',
      opacity: 0.7
    }
  };
});

var variants = {
  light: {
    textColor: 'white',
    inputColor: 'white',
    iconColor: 'white',
    iconColorActive: 'blue.40'
  },
  dark: {
    textColor: 'blue.40',
    inputColor: 'gray.10',
    iconColor: 'gray.10',
    iconColorActive: 'blue.40'
  }
};

var _StyledButtonBase = _styled(ButtonBase).withConfig({
  displayName: "SearchInput___StyledButtonBase",
  componentId: "sc-1jorkwy-1"
})(["&:hover,&:focus{color:", ";}"], function (p) {
  return p._css;
});

export var SearchInput = function SearchInput(_ref) {
  var _inputRef$current, _inputRef$current2;

  var inputProps = _ref.inputProps,
      _ref$variant = _ref.variant,
      variantName = _ref$variant === void 0 ? 'light' : _ref$variant,
      onChange = _ref.onChange,
      value = _ref.value,
      defaultValue = _ref.defaultValue,
      _ref$name = _ref.name,
      name = _ref$name === void 0 ? 'search' : _ref$name,
      _ref$isInitiallyActiv = _ref.isInitiallyActive,
      isInitiallyActive = _ref$isInitiallyActiv === void 0 ? false : _ref$isInitiallyActiv,
      props = _objectWithoutPropertiesLoose(_ref, ["inputProps", "variant", "onChange", "value", "defaultValue", "name", "isInitiallyActive"]);

  var inputRef = useRef();

  var _useState = useState(isInitiallyActive),
      isActive = _useState[0],
      setIsActive = _useState[1];

  var toggleIsActive = function toggleIsActive() {
    return setIsActive(function (state) {
      return !state;
    });
  };

  var animatedInputStyles = useSpring({
    pointerEvents: isActive ? 'auto' : 'none',
    opacity: isActive ? 1 : 0,
    onRest: isActive && ((_inputRef$current = inputRef.current) === null || _inputRef$current === void 0 ? void 0 : _inputRef$current.focus()),
    onStart: !isActive && ((_inputRef$current2 = inputRef.current) === null || _inputRef$current2 === void 0 ? void 0 : _inputRef$current2.blur())
  });
  var animatedLineStyles = useSpring({
    transform: isActive ? 'scaleX(1)' : 'scaleX(0)'
  });
  var variant = variants[variantName];
  return React.createElement(Flex, Object.assign({
    alignItems: "center",
    position: "relative",
    pbScale: "s"
  }, props), React.createElement(_StyledButtonBase, {
    onClick: toggleIsActive,
    type: "button",
    outline: "none",
    mrScale: "m-",
    flexShrink: "0",
    color: isActive ? variant.iconColorActive : variant.iconColor,
    variant: variant,
    transitionProperty: "color",
    _css: themeGet("colors." + variant.iconColorActive)
  }, React.createElement(SVG, {
    x: 1,
    y: 1,
    svg: AssetIconSearchSVG,
    width: linearScale('29px', '67px', {
      count: 5
    })
  })), React.createElement(_StyledAnimInput, Object.assign({
    ref: inputRef,
    tanIndex: isActive ? 0 : -1,
    onChange: onChange,
    spellCheck: false,
    autoComplete: false,
    name: name,
    type: "search",
    value: value,
    defaultValue: defaultValue,
    color: variant.textColor,
    flexGrow: "1",
    fontFamily: "sansExtended",
    fontSizeScale: "xl",
    fontWeight: "thin",
    outline: "0",
    placeholder: "Search",
    pbScale: "t-",
    width: "100%",
    style: animatedInputStyles
  }, inputProps)), React.createElement(Box, {
    as: a(Box),
    style: animatedLineStyles,
    height: ['1px', null, null, '2px'],
    bg: variant.inputColor,
    position: "absolute",
    transformOrigin: "left",
    left: 0,
    right: 0,
    bottom: 0
  }));
};