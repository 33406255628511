module.exports = [{
  plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
  options: {
    "plugins": [],
    "id": "google_tagmanager_id"
  }
}, {
  plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
  options: {
    "plugins": [],
    "name": "Peter Vincent Architects",
    "short_name": "PVA",
    "start_url": "/",
    "background_color": "#000000",
    "theme_color": "#ffffff",
    "display": "minimal-ui",
    "icon": "/opt/build/repo/src/assets/manifest-icon.png"
  }
}, {
  plugin: require('../node_modules/@walltowall/gatsby-theme-ww-base/gatsby-browser.js'),
  options: {
    "plugins": [],
    "root": "/opt/build/repo",
    "siteTitle": "Peter Vincent Architects",
    "siteTitleShort": "PVA",
    "siteDescription": "Peter Vincent Architects website.",
    "siteUrl": "https://pvadev.netlify.com",
    "withNetlify": true
  }
}, {
  plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
  options: {
    "plugins": [],
    "repositoryName": "pvadev",
    "accessToken": "MC5YWEdCbVJFQUFDZ0FIMEts.77-977-977-977-977-9DO-_vWHvv73vv71MBu-_ve-_vTglMO-_ve-_ve-_ve-_ve-_ve-_ve-_ve-_vSXvv71tbn_vv73vv70",
    "fetchLinks": ["page.parent"],
    "schemas": {
      "form": {
        "Main": {
          "name": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Name"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "uid"
            }
          },
          "body": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "choices": {
                "text_field": {
                  "type": "Slice",
                  "fieldset": "Text Field",
                  "description": "Add a text field to your form.",
                  "icon": "text_fields",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "width": {
                      "type": "Select",
                      "config": {
                        "options": ["Full", "Half"],
                        "default_value": "Full",
                        "label": "Width"
                      }
                    },
                    "type": {
                      "type": "Select",
                      "config": {
                        "options": ["Plain Text", "Email", "Phone Number"],
                        "default_value": "Plain Text",
                        "label": "Type"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "label": "Help Text"
                      }
                    },
                    "placeholder_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Placeholder Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "checkbox_field": {
                  "type": "Slice",
                  "fieldset": "Checkbox Field",
                  "description": "Add one or more checkboxes to your form.",
                  "icon": "check_box",
                  "display": "list",
                  "non-repeat": {
                    "minimum_checks": {
                      "type": "Number",
                      "config": {
                        "label": "Minimum Checks"
                      }
                    },
                    "maximum_checks": {
                      "type": "Number",
                      "config": {
                        "label": "Maximum Checks"
                      }
                    },
                    "width": {
                      "type": "Select",
                      "config": {
                        "options": ["Full", "Half"],
                        "default_value": "Full",
                        "label": "Width"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    }
                  },
                  "repeat": {
                    "option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Option"
                      }
                    }
                  }
                },
                "select_field": {
                  "type": "Slice",
                  "fieldset": "Select Field",
                  "description": "Add a dropdown select field to your form.",
                  "icon": "arrow_drop_down_circle",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "width": {
                      "type": "Select",
                      "config": {
                        "options": ["Full", "Half"],
                        "default_value": "Full",
                        "label": "Width"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    },
                    "default_option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Default Option"
                      }
                    }
                  },
                  "repeat": {
                    "option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Option"
                      }
                    }
                  }
                },
                "radio_field": {
                  "type": "Slice",
                  "fieldset": "Radio Field",
                  "description": "Add one or more radio fields to your form.",
                  "icon": "radio_button_checked",
                  "display": "list",
                  "non-repeat": {
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    },
                    "default_option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Default Option"
                      }
                    }
                  },
                  "repeat": {
                    "option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Option"
                      }
                    }
                  }
                },
                "textarea": {
                  "type": "Slice",
                  "fieldset": "Textarea",
                  "description": "Add a large textarea field to your form.",
                  "icon": "wrap_text",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    },
                    "placeholder_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph",
                        "label": "Placeholder Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "help_text": {
                  "type": "Slice",
                  "fieldset": "Help Text",
                  "description": "Add helper text to help users.",
                  "icon": "help",
                  "display": "list",
                  "non-repeat": {
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    }
                  },
                  "repeat": {}
                }
              }
            }
          }
        }
      },
      "map": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "starting_coordinates": {
            "type": "GeoPoint",
            "config": {
              "label": "Starting Coordinates"
            }
          },
          "markers": {
            "type": "Group",
            "config": {
              "fields": {
                "name": {
                  "type": "StructuredText",
                  "config": {
                    "single": "heading2",
                    "label": "Name",
                    "placeholder": "Name of the marker"
                  }
                },
                "description": {
                  "type": "StructuredText",
                  "config": {
                    "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item",
                    "allowTargetBlank": true,
                    "label": "Description",
                    "placeholder": "Optional - Description of the marker"
                  }
                },
                "marker_type": {
                  "type": "Select",
                  "config": {
                    "options": ["A - PVA Logo", "P - Parking"],
                    "default_value": "A - PVA Logo",
                    "label": "Marker Type"
                  }
                },
                "geopoint": {
                  "type": "GeoPoint",
                  "config": {
                    "label": "GeoPoint"
                  }
                }
              },
              "label": "Markers"
            }
          }
        }
      },
      "navigation": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "root": {
            "type": "Link",
            "config": {
              "label": "Root",
              "placeholder": "Optional"
            }
          },
          "primary": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "choices": {
                "nav_item": {
                  "type": "Slice",
                  "fieldset": "Nav Item",
                  "description": "Navigation item",
                  "icon": "insert_link",
                  "display": "list",
                  "non-repeat": {
                    "name": {
                      "type": "Text",
                      "config": {
                        "label": "Name"
                      }
                    },
                    "link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Link"
                      }
                    }
                  },
                  "repeat": {}
                }
              }
            }
          }
        }
      },
      "news_category": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          }
        }
      },
      "page": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "meta_title": {
            "type": "Text",
            "config": {
              "label": "Meta Title"
            }
          },
          "meta_description": {
            "type": "Text",
            "config": {
              "label": "Meta Description"
            }
          },
          "parent": {
            "type": "Link",
            "config": {
              "select": "document",
              "customtypes": ["page"],
              "label": "Parent"
            }
          },
          "main": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "labels": {
                "news": []
              },
              "choices": {
                "404": {
                  "type": "Slice",
                  "fieldset": "404",
                  "description": "Use 404 - Page Not Found preset",
                  "icon": "signal_cellular_no_sim",
                  "display": "list",
                  "non-repeat": {
                    "large_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Large Text",
                        "placeholder": "large text"
                      }
                    },
                    "button_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Button Text",
                        "placeholder": "button text"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Text",
                        "placeholder": "Call to action message to redirect user to home page"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "label": "Button Link",
                        "placeholder": "link for button"
                      }
                    }
                  },
                  "repeat": {}
                },
                "project": {
                  "type": "Slice",
                  "fieldset": "Project",
                  "description": "Use project page preset.",
                  "icon": "location_city",
                  "display": "list",
                  "non-repeat": {
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Description"
                      }
                    },
                    "location": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Location"
                      }
                    },
                    "services": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "list-item",
                        "label": "Services",
                        "placeholder": "Optional"
                      }
                    },
                    "awards": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "list-item",
                        "label": "Awards",
                        "placeholder": "Optional"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    },
                    "intro_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Intro Image"
                      }
                    },
                    "intro_image_caption": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Intro Image Caption",
                        "placeholder": "Optional"
                      }
                    }
                  },
                  "repeat": {
                    "project_category": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["project_category"],
                        "label": "Project Category",
                        "placeholder": "Category for project"
                      }
                    }
                  }
                },
                "news": {
                  "type": "Slice",
                  "fieldset": "News",
                  "description": "Use news post preset.",
                  "icon": "event_note",
                  "display": "grid",
                  "non-repeat": {
                    "news_category": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["news_category"],
                        "label": "News Category"
                      }
                    },
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, em",
                        "allowTargetBlank": true,
                        "label": "Description"
                      }
                    },
                    "date": {
                      "type": "Date",
                      "config": {
                        "label": "Date"
                      }
                    },
                    "preview_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 1000
                        },
                        "thumbnails": [],
                        "label": "Preview Image"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    }
                  },
                  "repeat": {
                    "related_project": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Related Project"
                      }
                    }
                  }
                },
                "intro_text": {
                  "type": "Slice",
                  "fieldset": "Intro Text",
                  "description": "Use introduction text preset.",
                  "icon": "format_color_text",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph, strong, em",
                        "label": "Text",
                        "placeholder": "Text at start of the page"
                      }
                    },
                    "navigation": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["navigation"],
                        "label": "Navigation",
                        "placeholder": "Optional"
                      }
                    }
                  },
                  "repeat": {}
                },
                "horizontal_navigation": {
                  "type": "Slice",
                  "fieldset": "Horizontal Navigation",
                  "description": "Use horizontal navigation preset.",
                  "icon": "more_horiz",
                  "display": "list",
                  "non-repeat": {
                    "navigation": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["navigation"],
                        "label": "Navigation",
                        "placeholder": "Navigation to display"
                      }
                    }
                  },
                  "repeat": {}
                },
                "floating_navigation": {
                  "type": "Slice",
                  "fieldset": "Floating Navigation",
                  "description": "Use floating navigation preset.",
                  "icon": "cloud_queue",
                  "display": "list",
                  "non-repeat": {
                    "navigation": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["navigation"],
                        "label": "Navigation",
                        "placeholder": "Menu to use"
                      }
                    }
                  },
                  "repeat": {}
                },
                "button_navigation": {
                  "type": "Slice",
                  "fieldset": "Button Navigation",
                  "description": "Use button navigation preset.",
                  "icon": "radio_button_checked",
                  "display": "list",
                  "non-repeat": {
                    "navigation": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["navigation"],
                        "label": "Navigation",
                        "placeholder": "Primary navigation to display on the left"
                      }
                    },
                    "button_navigation": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["navigation"],
                        "label": "Button Navigation",
                        "placeholder": "Secondary navigation to display on the right as buttons"
                      }
                    }
                  },
                  "repeat": {}
                },
                "design_category": {
                  "type": "Slice",
                  "fieldset": "Design Category",
                  "description": "Use design category preset.",
                  "icon": "widgets",
                  "display": "list",
                  "non-repeat": {
                    "project_category": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["project_category"],
                        "label": "Project Category"
                      }
                    },
                    "use_load_more_feature": {
                      "type": "Select",
                      "config": {
                        "options": ["False", "True"],
                        "default_value": "False",
                        "label": "Use Load More Feature",
                        "placeholder": "set it to true if you want to use load more button"
                      }
                    }
                  },
                  "repeat": {}
                },
                "news_category": {
                  "type": "Slice",
                  "fieldset": "News Category",
                  "description": "Use news category preset.",
                  "icon": "receipt",
                  "display": "list",
                  "non-repeat": {
                    "news_category": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["news_category"],
                        "label": "News Category"
                      }
                    }
                  },
                  "repeat": {}
                },
                "home": {
                  "type": "Slice",
                  "fieldset": "Home",
                  "description": "Use home page preset.",
                  "icon": "home",
                  "display": "list",
                  "non-repeat": {
                    "callout_color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Light", "Dark"],
                        "default_value": "Light",
                        "label": "Callout Color Scheme",
                        "placeholder": "Determines the color scheme of the callout"
                      }
                    },
                    "callout_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1",
                        "label": "Callout Text"
                      }
                    },
                    "callout_button_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Callout Button Text",
                        "placeholder": "(Optional)"
                      }
                    },
                    "callout_button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Callout Button Link",
                        "placeholder": "(Optional)"
                      }
                    },
                    "callout_background_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 1000
                        },
                        "thumbnails": [],
                        "label": "Callout Background Image"
                      }
                    },
                    "featured_news": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["News"],
                        "label": "Featured News"
                      }
                    }
                  },
                  "repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "category": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["project_category"],
                        "label": "Category"
                      }
                    },
                    "project": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project"
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "Body": {
          "body": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "labels": {
                "team_members": [],
                "project_cards": []
              },
              "choices": {
                "text": {
                  "type": "Slice",
                  "fieldset": "Text",
                  "description": "Full width block for displaying text.",
                  "icon": "format_align_center",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text",
                        "placeholder": "Text shown in slice"
                      }
                    }
                  },
                  "repeat": {}
                },
                "images": {
                  "type": "Slice",
                  "fieldset": "Images",
                  "description": "Block for displaying one or more images.",
                  "icon": "image",
                  "display": "grid",
                  "non-repeat": {},
                  "repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "caption": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Caption",
                        "placeholder": "Optional - Caption below image"
                      }
                    }
                  }
                },
                "image_with_text": {
                  "type": "Slice",
                  "fieldset": "Image With Text",
                  "description": "Block for displaying an image along with small text.",
                  "icon": "web",
                  "display": "list",
                  "non-repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "caption": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Caption",
                        "placeholder": "Optional - Caption below image"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text",
                        "placeholder": "Text alongside the selected image."
                      }
                    }
                  },
                  "repeat": {}
                },
                "small_image": {
                  "type": "Slice",
                  "fieldset": "Small Image",
                  "description": "Single centerd image.",
                  "icon": "image",
                  "display": "list",
                  "non-repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "featured_projects": {
                  "type": "Slice",
                  "fieldset": "Featured Projects",
                  "description": "Large project cards linked to their pages.",
                  "icon": "dashboard",
                  "display": "list",
                  "non-repeat": {
                    "project_1": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 1",
                        "placeholder": "Large project on left side"
                      }
                    },
                    "project_2": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 2",
                        "placeholder": "Large project on right side"
                      }
                    }
                  },
                  "repeat": {}
                },
                "small_projects": {
                  "type": "Slice",
                  "fieldset": "Small Projects",
                  "description": "Small project cards linked to their pages.",
                  "icon": "dashboard",
                  "display": "list",
                  "non-repeat": {
                    "project_1": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 1",
                        "placeholder": "First project to display"
                      }
                    },
                    "project_2": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 2",
                        "placeholder": "Second project to display"
                      }
                    },
                    "project_3": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 3",
                        "placeholder": "Third project to display"
                      }
                    },
                    "project_4": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 4",
                        "placeholder": "Fourth project to display"
                      }
                    }
                  },
                  "repeat": {}
                },
                "mixed_sized_projects": {
                  "type": "Slice",
                  "fieldset": "Mixed Sized Projects",
                  "description": "Mixed sized project cards linked to their pages.",
                  "icon": "dashboard",
                  "display": "list",
                  "non-repeat": {
                    "large_project_side": {
                      "type": "Select",
                      "config": {
                        "options": ["Left", "Right"],
                        "default_value": "Left",
                        "label": "Large Project Side"
                      }
                    },
                    "large_project_1": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Large Project 1",
                        "placeholder": "Project to display"
                      }
                    },
                    "small_project_1": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Small Project 1",
                        "placeholder": "Project to display"
                      }
                    },
                    "small_project_2": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Small Project 2",
                        "placeholder": "Project to display"
                      }
                    },
                    "small_project_3": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Small Project 3",
                        "placeholder": "Project to display"
                      }
                    },
                    "small_project_4": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Small Project 4",
                        "placeholder": "Project to display"
                      }
                    }
                  },
                  "repeat": {}
                },
                "awards": {
                  "type": "Slice",
                  "fieldset": "Awards",
                  "description": "Repeatable list of awards.",
                  "icon": "grade",
                  "display": "list",
                  "non-repeat": {
                    "project_image_side": {
                      "type": "Select",
                      "config": {
                        "options": ["Left", "Right"],
                        "default_value": "Left",
                        "label": "Project Image Side",
                        "placeholder": "Side that the example project image will display"
                      }
                    },
                    "organization": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Organization",
                        "placeholder": "Name of granting organization"
                      }
                    },
                    "organization_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 1000
                        },
                        "thumbnails": [],
                        "label": "Organization Image"
                      }
                    },
                    "example_project": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "label": "Example Project",
                        "placeholder": "Appears alongside list of awards"
                      }
                    }
                  },
                  "repeat": {
                    "year": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Year",
                        "placeholder": "Year award was given"
                      }
                    },
                    "awards": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Awards",
                        "placeholder": "List of awards for year"
                      }
                    }
                  }
                },
                "branded_callout": {
                  "type": "Slice",
                  "fieldset": "Branded Callout",
                  "description": "Callout with text, logo, and optional button link.",
                  "icon": "folder_special",
                  "display": "list",
                  "non-repeat": {
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Light", "Dark"],
                        "default_value": "Light",
                        "label": "Color Scheme"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Text",
                        "placeholder": "Large text in callout"
                      }
                    },
                    "button_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Button Text",
                        "placeholder": "Optional - Text inside button"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "placeholder": "Optional - URL associated with button click"
                      }
                    },
                    "background_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 1000
                        },
                        "thumbnails": [],
                        "label": "Background Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "callout_with_news": {
                  "type": "Slice",
                  "fieldset": "Callout With News",
                  "description": "Branded callout with featured news.",
                  "icon": "fiber_new",
                  "display": "list",
                  "non-repeat": {
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Light", "Dark"],
                        "default_value": "Light",
                        "label": "Color Scheme"
                      }
                    },
                    "callout_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Callout Text",
                        "placeholder": "Text shown in callout"
                      }
                    },
                    "callout_button_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Callout Button Text",
                        "placeholder": "Optional - Text inside callout button"
                      }
                    },
                    "callout_button_link": {
                      "type": "Link",
                      "config": {
                        "label": "Callout Button Link",
                        "allowTargetBlank": true,
                        "placeholder": "Optional - URL associated with button"
                      }
                    },
                    "featured_news": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["News"],
                        "label": "Featured News",
                        "placeholder": "News article to display"
                      }
                    }
                  },
                  "repeat": {}
                },
                "career_openings": {
                  "type": "Slice",
                  "fieldset": "Career Openings",
                  "description": "List of available career openings.",
                  "icon": "work",
                  "display": "list",
                  "non-repeat": {
                    "evergreen_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, heading2, strong, em, hyperlink, embed, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Evergreen Text",
                        "placeholder": "Optional - Text to display at all times below current openings"
                      }
                    }
                  },
                  "repeat": {
                    "position_title": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Position Title",
                        "placeholder": "Official title"
                      }
                    },
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Description",
                        "placeholder": "Concise info about position"
                      }
                    },
                    "qualifications": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Qualifications",
                        "placeholder": "List of qualifications"
                      }
                    },
                    "to_apply_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "To Apply Text",
                        "placeholder": "Instructions to apply"
                      }
                    }
                  }
                },
                "featured_news_post": {
                  "type": "Slice",
                  "fieldset": "Featured News Post",
                  "description": "Large news post with image.",
                  "icon": "art_track",
                  "display": "list",
                  "non-repeat": {
                    "news_post": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["News"],
                        "label": "News Post",
                        "placeholder": "News post to feature"
                      }
                    }
                  },
                  "repeat": {}
                },
                "map": {
                  "type": "Slice",
                  "fieldset": "Map",
                  "description": "Map with contact information",
                  "icon": "map",
                  "display": "list",
                  "non-repeat": {
                    "map": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["map"],
                        "label": "Map",
                        "placeholder": "Map to display adjacent to text"
                      }
                    },
                    "address": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, hyperlink, list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Address",
                        "placeholder": "Office address"
                      }
                    },
                    "contact_information": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Contact Information",
                        "placeholder": "Basic contact information - address, phone, etc"
                      }
                    },
                    "footnote": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, hyperlink, list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Footnote",
                        "placeholder": "Small text footnote under the contact information"
                      }
                    }
                  },
                  "repeat": {}
                },
                "project_cards": {
                  "type": "Slice",
                  "fieldset": "Project Cards",
                  "description": "List of project card links with thumb image",
                  "icon": "view_module",
                  "display": "grid",
                  "non-repeat": {
                    "list_name": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "List Name",
                        "placeholder": "Heading displayed next to projects"
                      }
                    }
                  },
                  "repeat": {
                    "project": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project",
                        "placeholder": "Project to display"
                      }
                    }
                  }
                },
                "service": {
                  "type": "Slice",
                  "fieldset": "Service",
                  "description": "Description of a service with featured project images.",
                  "icon": "art_track",
                  "display": "list",
                  "non-repeat": {
                    "text_align": {
                      "type": "Select",
                      "config": {
                        "options": ["right", "left"],
                        "default_value": "right",
                        "label": "Text Align"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 1000
                        },
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    },
                    "title1": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Title",
                        "placeholder": "Name of the service"
                      }
                    },
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, list-item, o-list-item, o-list-item",
                        "label": "Description",
                        "placeholder": "Description about the service"
                      }
                    },
                    "button_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Button Text",
                        "placeholder": "Optional - Text within button"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "placeholder": "Optional - URL associated with button"
                      }
                    }
                  },
                  "repeat": {
                    "featured_projects": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Featured Projects",
                        "placeholder": "Project related to this service"
                      }
                    }
                  }
                },
                "team_members": {
                  "type": "Slice",
                  "fieldset": "Team Members",
                  "description": "List of team members and their facts.",
                  "icon": "group",
                  "display": "grid",
                  "non-repeat": {
                    "heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Heading",
                        "placeholder": "Optional - Text above list of names"
                      }
                    }
                  },
                  "repeat": {
                    "team_member": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["team_member"],
                        "label": "Team Member",
                        "placeholder": "Team member to display"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "project_category": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "TItle"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "color": {
            "type": "Select",
            "config": {
              "options": ["Orange", "Green", "Blue", "Yellow", "Pink", "Brown", "Gray"],
              "default_value": "Orange",
              "label": "Color"
            }
          }
        }
      },
      "settings": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "site_name": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Site Name"
            }
          },
          "site_description": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Site Description"
            }
          },
          "site_copyright": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Site Copyright"
            }
          },
          "facebook_handle": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Facebook Handle"
            }
          },
          "twitter_handle": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Twitter Handle"
            }
          },
          "instagram_handle": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Instagram Handle"
            }
          },
          "linkedin_handle": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "LinkedIn Handle"
            }
          },
          "preview_map": {
            "type": "Group",
            "config": {
              "fields": {
                "api_id": {
                  "type": "Text",
                  "config": {
                    "label": "API ID"
                  }
                },
                "page": {
                  "type": "Link",
                  "config": {
                    "label": "Page"
                  }
                }
              },
              "label": "Preview Map"
            }
          }
        }
      },
      "team_member": {
        "Main": {
          "name": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Name",
              "placeholder": "Team member name"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "position_title": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "allowTargetBlank": true,
              "label": "Position Title",
              "placeholder": "Official job title"
            }
          },
          "personal_tweet": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Personal Tweet",
              "placeholder": "140 characters or less that represents this team member"
            }
          },
          "headshot": {
            "type": "Image",
            "config": {
              "constraint": {
                "width": 1000
              },
              "thumbnails": [],
              "label": "Headshot"
            }
          },
          "certifications": {
            "type": "Group",
            "config": {
              "fields": {
                "certification_name": {
                  "type": "StructuredText",
                  "config": {
                    "single": "paragraph",
                    "label": "Certification Name",
                    "placeholder": "Certification acronym"
                  }
                }
              },
              "label": "Certifications"
            }
          }
        }
      }
    }
  }
}, {
  plugin: require('../node_modules/@walltowall/gatsby-theme-ww-prismic/gatsby-browser.js'),
  options: {
    "plugins": [],
    "root": "/opt/build/repo",
    "repositoryName": "pvadev",
    "accessToken": "MC5YWEdCbVJFQUFDZ0FIMEts.77-977-977-977-977-9DO-_vWHvv73vv71MBu-_ve-_vTglMO-_ve-_ve-_ve-_ve-_ve-_ve-_ve-_vSXvv71tbn_vv73vv70",
    "defaultTemplate": "page",
    "schemas": {
      "form": {
        "Main": {
          "name": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Name"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "uid"
            }
          },
          "body": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "choices": {
                "text_field": {
                  "type": "Slice",
                  "fieldset": "Text Field",
                  "description": "Add a text field to your form.",
                  "icon": "text_fields",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "width": {
                      "type": "Select",
                      "config": {
                        "options": ["Full", "Half"],
                        "default_value": "Full",
                        "label": "Width"
                      }
                    },
                    "type": {
                      "type": "Select",
                      "config": {
                        "options": ["Plain Text", "Email", "Phone Number"],
                        "default_value": "Plain Text",
                        "label": "Type"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "label": "Help Text"
                      }
                    },
                    "placeholder_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Placeholder Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "checkbox_field": {
                  "type": "Slice",
                  "fieldset": "Checkbox Field",
                  "description": "Add one or more checkboxes to your form.",
                  "icon": "check_box",
                  "display": "list",
                  "non-repeat": {
                    "minimum_checks": {
                      "type": "Number",
                      "config": {
                        "label": "Minimum Checks"
                      }
                    },
                    "maximum_checks": {
                      "type": "Number",
                      "config": {
                        "label": "Maximum Checks"
                      }
                    },
                    "width": {
                      "type": "Select",
                      "config": {
                        "options": ["Full", "Half"],
                        "default_value": "Full",
                        "label": "Width"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    }
                  },
                  "repeat": {
                    "option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Option"
                      }
                    }
                  }
                },
                "select_field": {
                  "type": "Slice",
                  "fieldset": "Select Field",
                  "description": "Add a dropdown select field to your form.",
                  "icon": "arrow_drop_down_circle",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "width": {
                      "type": "Select",
                      "config": {
                        "options": ["Full", "Half"],
                        "default_value": "Full",
                        "label": "Width"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    },
                    "default_option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Default Option"
                      }
                    }
                  },
                  "repeat": {
                    "option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Option"
                      }
                    }
                  }
                },
                "radio_field": {
                  "type": "Slice",
                  "fieldset": "Radio Field",
                  "description": "Add one or more radio fields to your form.",
                  "icon": "radio_button_checked",
                  "display": "list",
                  "non-repeat": {
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    },
                    "default_option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Default Option"
                      }
                    }
                  },
                  "repeat": {
                    "option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Option"
                      }
                    }
                  }
                },
                "textarea": {
                  "type": "Slice",
                  "fieldset": "Textarea",
                  "description": "Add a large textarea field to your form.",
                  "icon": "wrap_text",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    },
                    "placeholder_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph",
                        "label": "Placeholder Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "help_text": {
                  "type": "Slice",
                  "fieldset": "Help Text",
                  "description": "Add helper text to help users.",
                  "icon": "help",
                  "display": "list",
                  "non-repeat": {
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    }
                  },
                  "repeat": {}
                }
              }
            }
          }
        }
      },
      "map": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "starting_coordinates": {
            "type": "GeoPoint",
            "config": {
              "label": "Starting Coordinates"
            }
          },
          "markers": {
            "type": "Group",
            "config": {
              "fields": {
                "name": {
                  "type": "StructuredText",
                  "config": {
                    "single": "heading2",
                    "label": "Name",
                    "placeholder": "Name of the marker"
                  }
                },
                "description": {
                  "type": "StructuredText",
                  "config": {
                    "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item",
                    "allowTargetBlank": true,
                    "label": "Description",
                    "placeholder": "Optional - Description of the marker"
                  }
                },
                "marker_type": {
                  "type": "Select",
                  "config": {
                    "options": ["A - PVA Logo", "P - Parking"],
                    "default_value": "A - PVA Logo",
                    "label": "Marker Type"
                  }
                },
                "geopoint": {
                  "type": "GeoPoint",
                  "config": {
                    "label": "GeoPoint"
                  }
                }
              },
              "label": "Markers"
            }
          }
        }
      },
      "navigation": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "root": {
            "type": "Link",
            "config": {
              "label": "Root",
              "placeholder": "Optional"
            }
          },
          "primary": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "choices": {
                "nav_item": {
                  "type": "Slice",
                  "fieldset": "Nav Item",
                  "description": "Navigation item",
                  "icon": "insert_link",
                  "display": "list",
                  "non-repeat": {
                    "name": {
                      "type": "Text",
                      "config": {
                        "label": "Name"
                      }
                    },
                    "link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Link"
                      }
                    }
                  },
                  "repeat": {}
                }
              }
            }
          }
        }
      },
      "news_category": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          }
        }
      },
      "page": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "meta_title": {
            "type": "Text",
            "config": {
              "label": "Meta Title"
            }
          },
          "meta_description": {
            "type": "Text",
            "config": {
              "label": "Meta Description"
            }
          },
          "parent": {
            "type": "Link",
            "config": {
              "select": "document",
              "customtypes": ["page"],
              "label": "Parent"
            }
          },
          "main": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "labels": {
                "news": []
              },
              "choices": {
                "404": {
                  "type": "Slice",
                  "fieldset": "404",
                  "description": "Use 404 - Page Not Found preset",
                  "icon": "signal_cellular_no_sim",
                  "display": "list",
                  "non-repeat": {
                    "large_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Large Text",
                        "placeholder": "large text"
                      }
                    },
                    "button_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Button Text",
                        "placeholder": "button text"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Text",
                        "placeholder": "Call to action message to redirect user to home page"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "label": "Button Link",
                        "placeholder": "link for button"
                      }
                    }
                  },
                  "repeat": {}
                },
                "project": {
                  "type": "Slice",
                  "fieldset": "Project",
                  "description": "Use project page preset.",
                  "icon": "location_city",
                  "display": "list",
                  "non-repeat": {
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Description"
                      }
                    },
                    "location": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Location"
                      }
                    },
                    "services": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "list-item",
                        "label": "Services",
                        "placeholder": "Optional"
                      }
                    },
                    "awards": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "list-item",
                        "label": "Awards",
                        "placeholder": "Optional"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    },
                    "intro_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Intro Image"
                      }
                    },
                    "intro_image_caption": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Intro Image Caption",
                        "placeholder": "Optional"
                      }
                    }
                  },
                  "repeat": {
                    "project_category": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["project_category"],
                        "label": "Project Category",
                        "placeholder": "Category for project"
                      }
                    }
                  }
                },
                "news": {
                  "type": "Slice",
                  "fieldset": "News",
                  "description": "Use news post preset.",
                  "icon": "event_note",
                  "display": "grid",
                  "non-repeat": {
                    "news_category": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["news_category"],
                        "label": "News Category"
                      }
                    },
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, em",
                        "allowTargetBlank": true,
                        "label": "Description"
                      }
                    },
                    "date": {
                      "type": "Date",
                      "config": {
                        "label": "Date"
                      }
                    },
                    "preview_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 1000
                        },
                        "thumbnails": [],
                        "label": "Preview Image"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    }
                  },
                  "repeat": {
                    "related_project": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Related Project"
                      }
                    }
                  }
                },
                "intro_text": {
                  "type": "Slice",
                  "fieldset": "Intro Text",
                  "description": "Use introduction text preset.",
                  "icon": "format_color_text",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph, strong, em",
                        "label": "Text",
                        "placeholder": "Text at start of the page"
                      }
                    },
                    "navigation": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["navigation"],
                        "label": "Navigation",
                        "placeholder": "Optional"
                      }
                    }
                  },
                  "repeat": {}
                },
                "horizontal_navigation": {
                  "type": "Slice",
                  "fieldset": "Horizontal Navigation",
                  "description": "Use horizontal navigation preset.",
                  "icon": "more_horiz",
                  "display": "list",
                  "non-repeat": {
                    "navigation": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["navigation"],
                        "label": "Navigation",
                        "placeholder": "Navigation to display"
                      }
                    }
                  },
                  "repeat": {}
                },
                "floating_navigation": {
                  "type": "Slice",
                  "fieldset": "Floating Navigation",
                  "description": "Use floating navigation preset.",
                  "icon": "cloud_queue",
                  "display": "list",
                  "non-repeat": {
                    "navigation": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["navigation"],
                        "label": "Navigation",
                        "placeholder": "Menu to use"
                      }
                    }
                  },
                  "repeat": {}
                },
                "button_navigation": {
                  "type": "Slice",
                  "fieldset": "Button Navigation",
                  "description": "Use button navigation preset.",
                  "icon": "radio_button_checked",
                  "display": "list",
                  "non-repeat": {
                    "navigation": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["navigation"],
                        "label": "Navigation",
                        "placeholder": "Primary navigation to display on the left"
                      }
                    },
                    "button_navigation": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["navigation"],
                        "label": "Button Navigation",
                        "placeholder": "Secondary navigation to display on the right as buttons"
                      }
                    }
                  },
                  "repeat": {}
                },
                "design_category": {
                  "type": "Slice",
                  "fieldset": "Design Category",
                  "description": "Use design category preset.",
                  "icon": "widgets",
                  "display": "list",
                  "non-repeat": {
                    "project_category": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["project_category"],
                        "label": "Project Category"
                      }
                    },
                    "use_load_more_feature": {
                      "type": "Select",
                      "config": {
                        "options": ["False", "True"],
                        "default_value": "False",
                        "label": "Use Load More Feature",
                        "placeholder": "set it to true if you want to use load more button"
                      }
                    }
                  },
                  "repeat": {}
                },
                "news_category": {
                  "type": "Slice",
                  "fieldset": "News Category",
                  "description": "Use news category preset.",
                  "icon": "receipt",
                  "display": "list",
                  "non-repeat": {
                    "news_category": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["news_category"],
                        "label": "News Category"
                      }
                    }
                  },
                  "repeat": {}
                },
                "home": {
                  "type": "Slice",
                  "fieldset": "Home",
                  "description": "Use home page preset.",
                  "icon": "home",
                  "display": "list",
                  "non-repeat": {
                    "callout_color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Light", "Dark"],
                        "default_value": "Light",
                        "label": "Callout Color Scheme",
                        "placeholder": "Determines the color scheme of the callout"
                      }
                    },
                    "callout_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1",
                        "label": "Callout Text"
                      }
                    },
                    "callout_button_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Callout Button Text",
                        "placeholder": "(Optional)"
                      }
                    },
                    "callout_button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Callout Button Link",
                        "placeholder": "(Optional)"
                      }
                    },
                    "callout_background_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 1000
                        },
                        "thumbnails": [],
                        "label": "Callout Background Image"
                      }
                    },
                    "featured_news": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["News"],
                        "label": "Featured News"
                      }
                    }
                  },
                  "repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "category": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["project_category"],
                        "label": "Category"
                      }
                    },
                    "project": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project"
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "Body": {
          "body": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "labels": {
                "team_members": [],
                "project_cards": []
              },
              "choices": {
                "text": {
                  "type": "Slice",
                  "fieldset": "Text",
                  "description": "Full width block for displaying text.",
                  "icon": "format_align_center",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text",
                        "placeholder": "Text shown in slice"
                      }
                    }
                  },
                  "repeat": {}
                },
                "images": {
                  "type": "Slice",
                  "fieldset": "Images",
                  "description": "Block for displaying one or more images.",
                  "icon": "image",
                  "display": "grid",
                  "non-repeat": {},
                  "repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "caption": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Caption",
                        "placeholder": "Optional - Caption below image"
                      }
                    }
                  }
                },
                "image_with_text": {
                  "type": "Slice",
                  "fieldset": "Image With Text",
                  "description": "Block for displaying an image along with small text.",
                  "icon": "web",
                  "display": "list",
                  "non-repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "caption": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Caption",
                        "placeholder": "Optional - Caption below image"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text",
                        "placeholder": "Text alongside the selected image."
                      }
                    }
                  },
                  "repeat": {}
                },
                "small_image": {
                  "type": "Slice",
                  "fieldset": "Small Image",
                  "description": "Single centerd image.",
                  "icon": "image",
                  "display": "list",
                  "non-repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "featured_projects": {
                  "type": "Slice",
                  "fieldset": "Featured Projects",
                  "description": "Large project cards linked to their pages.",
                  "icon": "dashboard",
                  "display": "list",
                  "non-repeat": {
                    "project_1": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 1",
                        "placeholder": "Large project on left side"
                      }
                    },
                    "project_2": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 2",
                        "placeholder": "Large project on right side"
                      }
                    }
                  },
                  "repeat": {}
                },
                "small_projects": {
                  "type": "Slice",
                  "fieldset": "Small Projects",
                  "description": "Small project cards linked to their pages.",
                  "icon": "dashboard",
                  "display": "list",
                  "non-repeat": {
                    "project_1": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 1",
                        "placeholder": "First project to display"
                      }
                    },
                    "project_2": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 2",
                        "placeholder": "Second project to display"
                      }
                    },
                    "project_3": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 3",
                        "placeholder": "Third project to display"
                      }
                    },
                    "project_4": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 4",
                        "placeholder": "Fourth project to display"
                      }
                    }
                  },
                  "repeat": {}
                },
                "mixed_sized_projects": {
                  "type": "Slice",
                  "fieldset": "Mixed Sized Projects",
                  "description": "Mixed sized project cards linked to their pages.",
                  "icon": "dashboard",
                  "display": "list",
                  "non-repeat": {
                    "large_project_side": {
                      "type": "Select",
                      "config": {
                        "options": ["Left", "Right"],
                        "default_value": "Left",
                        "label": "Large Project Side"
                      }
                    },
                    "large_project_1": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Large Project 1",
                        "placeholder": "Project to display"
                      }
                    },
                    "small_project_1": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Small Project 1",
                        "placeholder": "Project to display"
                      }
                    },
                    "small_project_2": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Small Project 2",
                        "placeholder": "Project to display"
                      }
                    },
                    "small_project_3": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Small Project 3",
                        "placeholder": "Project to display"
                      }
                    },
                    "small_project_4": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Small Project 4",
                        "placeholder": "Project to display"
                      }
                    }
                  },
                  "repeat": {}
                },
                "awards": {
                  "type": "Slice",
                  "fieldset": "Awards",
                  "description": "Repeatable list of awards.",
                  "icon": "grade",
                  "display": "list",
                  "non-repeat": {
                    "project_image_side": {
                      "type": "Select",
                      "config": {
                        "options": ["Left", "Right"],
                        "default_value": "Left",
                        "label": "Project Image Side",
                        "placeholder": "Side that the example project image will display"
                      }
                    },
                    "organization": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Organization",
                        "placeholder": "Name of granting organization"
                      }
                    },
                    "organization_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 1000
                        },
                        "thumbnails": [],
                        "label": "Organization Image"
                      }
                    },
                    "example_project": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "label": "Example Project",
                        "placeholder": "Appears alongside list of awards"
                      }
                    }
                  },
                  "repeat": {
                    "year": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Year",
                        "placeholder": "Year award was given"
                      }
                    },
                    "awards": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Awards",
                        "placeholder": "List of awards for year"
                      }
                    }
                  }
                },
                "branded_callout": {
                  "type": "Slice",
                  "fieldset": "Branded Callout",
                  "description": "Callout with text, logo, and optional button link.",
                  "icon": "folder_special",
                  "display": "list",
                  "non-repeat": {
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Light", "Dark"],
                        "default_value": "Light",
                        "label": "Color Scheme"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Text",
                        "placeholder": "Large text in callout"
                      }
                    },
                    "button_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Button Text",
                        "placeholder": "Optional - Text inside button"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "placeholder": "Optional - URL associated with button click"
                      }
                    },
                    "background_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 1000
                        },
                        "thumbnails": [],
                        "label": "Background Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "callout_with_news": {
                  "type": "Slice",
                  "fieldset": "Callout With News",
                  "description": "Branded callout with featured news.",
                  "icon": "fiber_new",
                  "display": "list",
                  "non-repeat": {
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Light", "Dark"],
                        "default_value": "Light",
                        "label": "Color Scheme"
                      }
                    },
                    "callout_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Callout Text",
                        "placeholder": "Text shown in callout"
                      }
                    },
                    "callout_button_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Callout Button Text",
                        "placeholder": "Optional - Text inside callout button"
                      }
                    },
                    "callout_button_link": {
                      "type": "Link",
                      "config": {
                        "label": "Callout Button Link",
                        "allowTargetBlank": true,
                        "placeholder": "Optional - URL associated with button"
                      }
                    },
                    "featured_news": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["News"],
                        "label": "Featured News",
                        "placeholder": "News article to display"
                      }
                    }
                  },
                  "repeat": {}
                },
                "career_openings": {
                  "type": "Slice",
                  "fieldset": "Career Openings",
                  "description": "List of available career openings.",
                  "icon": "work",
                  "display": "list",
                  "non-repeat": {
                    "evergreen_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, heading2, strong, em, hyperlink, embed, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Evergreen Text",
                        "placeholder": "Optional - Text to display at all times below current openings"
                      }
                    }
                  },
                  "repeat": {
                    "position_title": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Position Title",
                        "placeholder": "Official title"
                      }
                    },
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Description",
                        "placeholder": "Concise info about position"
                      }
                    },
                    "qualifications": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Qualifications",
                        "placeholder": "List of qualifications"
                      }
                    },
                    "to_apply_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "To Apply Text",
                        "placeholder": "Instructions to apply"
                      }
                    }
                  }
                },
                "featured_news_post": {
                  "type": "Slice",
                  "fieldset": "Featured News Post",
                  "description": "Large news post with image.",
                  "icon": "art_track",
                  "display": "list",
                  "non-repeat": {
                    "news_post": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["News"],
                        "label": "News Post",
                        "placeholder": "News post to feature"
                      }
                    }
                  },
                  "repeat": {}
                },
                "map": {
                  "type": "Slice",
                  "fieldset": "Map",
                  "description": "Map with contact information",
                  "icon": "map",
                  "display": "list",
                  "non-repeat": {
                    "map": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["map"],
                        "label": "Map",
                        "placeholder": "Map to display adjacent to text"
                      }
                    },
                    "address": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, hyperlink, list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Address",
                        "placeholder": "Office address"
                      }
                    },
                    "contact_information": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Contact Information",
                        "placeholder": "Basic contact information - address, phone, etc"
                      }
                    },
                    "footnote": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, hyperlink, list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Footnote",
                        "placeholder": "Small text footnote under the contact information"
                      }
                    }
                  },
                  "repeat": {}
                },
                "project_cards": {
                  "type": "Slice",
                  "fieldset": "Project Cards",
                  "description": "List of project card links with thumb image",
                  "icon": "view_module",
                  "display": "grid",
                  "non-repeat": {
                    "list_name": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "List Name",
                        "placeholder": "Heading displayed next to projects"
                      }
                    }
                  },
                  "repeat": {
                    "project": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project",
                        "placeholder": "Project to display"
                      }
                    }
                  }
                },
                "service": {
                  "type": "Slice",
                  "fieldset": "Service",
                  "description": "Description of a service with featured project images.",
                  "icon": "art_track",
                  "display": "list",
                  "non-repeat": {
                    "text_align": {
                      "type": "Select",
                      "config": {
                        "options": ["right", "left"],
                        "default_value": "right",
                        "label": "Text Align"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 1000
                        },
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    },
                    "title1": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Title",
                        "placeholder": "Name of the service"
                      }
                    },
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, list-item, o-list-item, o-list-item",
                        "label": "Description",
                        "placeholder": "Description about the service"
                      }
                    },
                    "button_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Button Text",
                        "placeholder": "Optional - Text within button"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "placeholder": "Optional - URL associated with button"
                      }
                    }
                  },
                  "repeat": {
                    "featured_projects": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Featured Projects",
                        "placeholder": "Project related to this service"
                      }
                    }
                  }
                },
                "team_members": {
                  "type": "Slice",
                  "fieldset": "Team Members",
                  "description": "List of team members and their facts.",
                  "icon": "group",
                  "display": "grid",
                  "non-repeat": {
                    "heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Heading",
                        "placeholder": "Optional - Text above list of names"
                      }
                    }
                  },
                  "repeat": {
                    "team_member": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["team_member"],
                        "label": "Team Member",
                        "placeholder": "Team member to display"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "project_category": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "TItle"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "color": {
            "type": "Select",
            "config": {
              "options": ["Orange", "Green", "Blue", "Yellow", "Pink", "Brown", "Gray"],
              "default_value": "Orange",
              "label": "Color"
            }
          }
        }
      },
      "settings": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "site_name": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Site Name"
            }
          },
          "site_description": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Site Description"
            }
          },
          "site_copyright": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Site Copyright"
            }
          },
          "facebook_handle": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Facebook Handle"
            }
          },
          "twitter_handle": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Twitter Handle"
            }
          },
          "instagram_handle": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Instagram Handle"
            }
          },
          "linkedin_handle": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "LinkedIn Handle"
            }
          },
          "preview_map": {
            "type": "Group",
            "config": {
              "fields": {
                "api_id": {
                  "type": "Text",
                  "config": {
                    "label": "API ID"
                  }
                },
                "page": {
                  "type": "Link",
                  "config": {
                    "label": "Page"
                  }
                }
              },
              "label": "Preview Map"
            }
          }
        }
      },
      "team_member": {
        "Main": {
          "name": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Name",
              "placeholder": "Team member name"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "position_title": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "allowTargetBlank": true,
              "label": "Position Title",
              "placeholder": "Official job title"
            }
          },
          "personal_tweet": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Personal Tweet",
              "placeholder": "140 characters or less that represents this team member"
            }
          },
          "headshot": {
            "type": "Image",
            "config": {
              "constraint": {
                "width": 1000
              },
              "thumbnails": [],
              "label": "Headshot"
            }
          },
          "certifications": {
            "type": "Group",
            "config": {
              "fields": {
                "certification_name": {
                  "type": "StructuredText",
                  "config": {
                    "single": "paragraph",
                    "label": "Certification Name",
                    "placeholder": "Certification acronym"
                  }
                }
              },
              "label": "Certifications"
            }
          }
        }
      }
    },
    "templates": {
      "pageNotFound": {
        "metadataType": "PrismicPageMain404"
      },
      "home": {
        "metadataType": "PrismicPageMainHome"
      },
      "project": {
        "metadataType": "PrismicPageMainProject"
      },
      "news": {
        "metadataType": "PrismicPageMainNews"
      },
      "horizontalNavigation": {
        "metadataType": "PrismicPageMainHorizontalNavigation"
      },
      "designCategory": {
        "metadataType": "PrismicPageMainDesignCategory"
      },
      "newsCategory": {
        "metadataType": "PrismicPageMainNewsCategory"
      },
      "floatingNavigation": {
        "metadataType": "PrismicPageMainFloatingNavigation"
      },
      "introText": {
        "metadataType": "PrismicPageMainIntroText"
      },
      "buttonNavigation": {
        "metadataType": "PrismicPageMainButtonNavigation"
      }
    }
  }
}, {
  plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
  options: {
    "plugins": [],
    "maxWidth": 768,
    "quality": 65,
    "showCaptions": true,
    "linkImagesToOriginal": false
  }
}, {
  plugin: require('../node_modules/@walltowall/gatsby-theme-ww-slice-studio/gatsby-browser.js'),
  options: {
    "plugins": [],
    "root": "/opt/build/repo",
    "siteTitle": "Peter Vincent Architects",
    "schemas": {
      "form": {
        "Main": {
          "name": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Name"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "uid"
            }
          },
          "body": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "choices": {
                "text_field": {
                  "type": "Slice",
                  "fieldset": "Text Field",
                  "description": "Add a text field to your form.",
                  "icon": "text_fields",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "width": {
                      "type": "Select",
                      "config": {
                        "options": ["Full", "Half"],
                        "default_value": "Full",
                        "label": "Width"
                      }
                    },
                    "type": {
                      "type": "Select",
                      "config": {
                        "options": ["Plain Text", "Email", "Phone Number"],
                        "default_value": "Plain Text",
                        "label": "Type"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "label": "Help Text"
                      }
                    },
                    "placeholder_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Placeholder Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "checkbox_field": {
                  "type": "Slice",
                  "fieldset": "Checkbox Field",
                  "description": "Add one or more checkboxes to your form.",
                  "icon": "check_box",
                  "display": "list",
                  "non-repeat": {
                    "minimum_checks": {
                      "type": "Number",
                      "config": {
                        "label": "Minimum Checks"
                      }
                    },
                    "maximum_checks": {
                      "type": "Number",
                      "config": {
                        "label": "Maximum Checks"
                      }
                    },
                    "width": {
                      "type": "Select",
                      "config": {
                        "options": ["Full", "Half"],
                        "default_value": "Full",
                        "label": "Width"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    }
                  },
                  "repeat": {
                    "option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Option"
                      }
                    }
                  }
                },
                "select_field": {
                  "type": "Slice",
                  "fieldset": "Select Field",
                  "description": "Add a dropdown select field to your form.",
                  "icon": "arrow_drop_down_circle",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "width": {
                      "type": "Select",
                      "config": {
                        "options": ["Full", "Half"],
                        "default_value": "Full",
                        "label": "Width"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    },
                    "default_option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Default Option"
                      }
                    }
                  },
                  "repeat": {
                    "option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Option"
                      }
                    }
                  }
                },
                "radio_field": {
                  "type": "Slice",
                  "fieldset": "Radio Field",
                  "description": "Add one or more radio fields to your form.",
                  "icon": "radio_button_checked",
                  "display": "list",
                  "non-repeat": {
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    },
                    "default_option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Default Option"
                      }
                    }
                  },
                  "repeat": {
                    "option": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Option"
                      }
                    }
                  }
                },
                "textarea": {
                  "type": "Slice",
                  "fieldset": "Textarea",
                  "description": "Add a large textarea field to your form.",
                  "icon": "wrap_text",
                  "display": "list",
                  "non-repeat": {
                    "required": {
                      "type": "Select",
                      "config": {
                        "options": ["Yes", "No"],
                        "default_value": "Yes",
                        "label": "Required"
                      }
                    },
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "help_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Help Text"
                      }
                    },
                    "placeholder_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph",
                        "label": "Placeholder Text"
                      }
                    }
                  },
                  "repeat": {}
                },
                "help_text": {
                  "type": "Slice",
                  "fieldset": "Help Text",
                  "description": "Add helper text to help users.",
                  "icon": "help",
                  "display": "list",
                  "non-repeat": {
                    "label": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Label"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text"
                      }
                    }
                  },
                  "repeat": {}
                }
              }
            }
          }
        }
      },
      "map": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "starting_coordinates": {
            "type": "GeoPoint",
            "config": {
              "label": "Starting Coordinates"
            }
          },
          "markers": {
            "type": "Group",
            "config": {
              "fields": {
                "name": {
                  "type": "StructuredText",
                  "config": {
                    "single": "heading2",
                    "label": "Name",
                    "placeholder": "Name of the marker"
                  }
                },
                "description": {
                  "type": "StructuredText",
                  "config": {
                    "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item",
                    "allowTargetBlank": true,
                    "label": "Description",
                    "placeholder": "Optional - Description of the marker"
                  }
                },
                "marker_type": {
                  "type": "Select",
                  "config": {
                    "options": ["A - PVA Logo", "P - Parking"],
                    "default_value": "A - PVA Logo",
                    "label": "Marker Type"
                  }
                },
                "geopoint": {
                  "type": "GeoPoint",
                  "config": {
                    "label": "GeoPoint"
                  }
                }
              },
              "label": "Markers"
            }
          }
        }
      },
      "navigation": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "root": {
            "type": "Link",
            "config": {
              "label": "Root",
              "placeholder": "Optional"
            }
          },
          "primary": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "choices": {
                "nav_item": {
                  "type": "Slice",
                  "fieldset": "Nav Item",
                  "description": "Navigation item",
                  "icon": "insert_link",
                  "display": "list",
                  "non-repeat": {
                    "name": {
                      "type": "Text",
                      "config": {
                        "label": "Name"
                      }
                    },
                    "link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Link"
                      }
                    }
                  },
                  "repeat": {}
                }
              }
            }
          }
        }
      },
      "news_category": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          }
        }
      },
      "page": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "meta_title": {
            "type": "Text",
            "config": {
              "label": "Meta Title"
            }
          },
          "meta_description": {
            "type": "Text",
            "config": {
              "label": "Meta Description"
            }
          },
          "parent": {
            "type": "Link",
            "config": {
              "select": "document",
              "customtypes": ["page"],
              "label": "Parent"
            }
          },
          "main": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "labels": {
                "news": []
              },
              "choices": {
                "404": {
                  "type": "Slice",
                  "fieldset": "404",
                  "description": "Use 404 - Page Not Found preset",
                  "icon": "signal_cellular_no_sim",
                  "display": "list",
                  "non-repeat": {
                    "large_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Large Text",
                        "placeholder": "large text"
                      }
                    },
                    "button_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Button Text",
                        "placeholder": "button text"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Text",
                        "placeholder": "Call to action message to redirect user to home page"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "label": "Button Link",
                        "placeholder": "link for button"
                      }
                    }
                  },
                  "repeat": {}
                },
                "project": {
                  "type": "Slice",
                  "fieldset": "Project",
                  "description": "Use project page preset.",
                  "icon": "location_city",
                  "display": "list",
                  "non-repeat": {
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Description"
                      }
                    },
                    "location": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Location"
                      }
                    },
                    "services": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "list-item",
                        "label": "Services",
                        "placeholder": "Optional"
                      }
                    },
                    "awards": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "list-item",
                        "label": "Awards",
                        "placeholder": "Optional"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    },
                    "intro_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Intro Image"
                      }
                    },
                    "intro_image_caption": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Intro Image Caption",
                        "placeholder": "Optional"
                      }
                    }
                  },
                  "repeat": {
                    "project_category": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["project_category"],
                        "label": "Project Category",
                        "placeholder": "Category for project"
                      }
                    }
                  }
                },
                "news": {
                  "type": "Slice",
                  "fieldset": "News",
                  "description": "Use news post preset.",
                  "icon": "event_note",
                  "display": "grid",
                  "non-repeat": {
                    "news_category": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["news_category"],
                        "label": "News Category"
                      }
                    },
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, em",
                        "allowTargetBlank": true,
                        "label": "Description"
                      }
                    },
                    "date": {
                      "type": "Date",
                      "config": {
                        "label": "Date"
                      }
                    },
                    "preview_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 1000
                        },
                        "thumbnails": [],
                        "label": "Preview Image"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    }
                  },
                  "repeat": {
                    "related_project": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Related Project"
                      }
                    }
                  }
                },
                "intro_text": {
                  "type": "Slice",
                  "fieldset": "Intro Text",
                  "description": "Use introduction text preset.",
                  "icon": "format_color_text",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph, strong, em",
                        "label": "Text",
                        "placeholder": "Text at start of the page"
                      }
                    },
                    "navigation": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["navigation"],
                        "label": "Navigation",
                        "placeholder": "Optional"
                      }
                    }
                  },
                  "repeat": {}
                },
                "horizontal_navigation": {
                  "type": "Slice",
                  "fieldset": "Horizontal Navigation",
                  "description": "Use horizontal navigation preset.",
                  "icon": "more_horiz",
                  "display": "list",
                  "non-repeat": {
                    "navigation": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["navigation"],
                        "label": "Navigation",
                        "placeholder": "Navigation to display"
                      }
                    }
                  },
                  "repeat": {}
                },
                "floating_navigation": {
                  "type": "Slice",
                  "fieldset": "Floating Navigation",
                  "description": "Use floating navigation preset.",
                  "icon": "cloud_queue",
                  "display": "list",
                  "non-repeat": {
                    "navigation": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["navigation"],
                        "label": "Navigation",
                        "placeholder": "Menu to use"
                      }
                    }
                  },
                  "repeat": {}
                },
                "button_navigation": {
                  "type": "Slice",
                  "fieldset": "Button Navigation",
                  "description": "Use button navigation preset.",
                  "icon": "radio_button_checked",
                  "display": "list",
                  "non-repeat": {
                    "navigation": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["navigation"],
                        "label": "Navigation",
                        "placeholder": "Primary navigation to display on the left"
                      }
                    },
                    "button_navigation": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["navigation"],
                        "label": "Button Navigation",
                        "placeholder": "Secondary navigation to display on the right as buttons"
                      }
                    }
                  },
                  "repeat": {}
                },
                "design_category": {
                  "type": "Slice",
                  "fieldset": "Design Category",
                  "description": "Use design category preset.",
                  "icon": "widgets",
                  "display": "list",
                  "non-repeat": {
                    "project_category": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["project_category"],
                        "label": "Project Category"
                      }
                    },
                    "use_load_more_feature": {
                      "type": "Select",
                      "config": {
                        "options": ["False", "True"],
                        "default_value": "False",
                        "label": "Use Load More Feature",
                        "placeholder": "set it to true if you want to use load more button"
                      }
                    }
                  },
                  "repeat": {}
                },
                "news_category": {
                  "type": "Slice",
                  "fieldset": "News Category",
                  "description": "Use news category preset.",
                  "icon": "receipt",
                  "display": "list",
                  "non-repeat": {
                    "news_category": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["news_category"],
                        "label": "News Category"
                      }
                    }
                  },
                  "repeat": {}
                },
                "home": {
                  "type": "Slice",
                  "fieldset": "Home",
                  "description": "Use home page preset.",
                  "icon": "home",
                  "display": "list",
                  "non-repeat": {
                    "callout_color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Light", "Dark"],
                        "default_value": "Light",
                        "label": "Callout Color Scheme",
                        "placeholder": "Determines the color scheme of the callout"
                      }
                    },
                    "callout_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1",
                        "label": "Callout Text"
                      }
                    },
                    "callout_button_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Callout Button Text",
                        "placeholder": "(Optional)"
                      }
                    },
                    "callout_button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Callout Button Link",
                        "placeholder": "(Optional)"
                      }
                    },
                    "callout_background_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 1000
                        },
                        "thumbnails": [],
                        "label": "Callout Background Image"
                      }
                    },
                    "featured_news": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["News"],
                        "label": "Featured News"
                      }
                    }
                  },
                  "repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "category": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["project_category"],
                        "label": "Category"
                      }
                    },
                    "project": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project"
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "Body": {
          "body": {
            "type": "Slices",
            "fieldset": "Slice zone",
            "config": {
              "labels": {
                "team_members": [],
                "project_cards": []
              },
              "choices": {
                "text": {
                  "type": "Slice",
                  "fieldset": "Text",
                  "description": "Full width block for displaying text.",
                  "icon": "format_align_center",
                  "display": "list",
                  "non-repeat": {
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text",
                        "placeholder": "Text shown in slice"
                      }
                    }
                  },
                  "repeat": {}
                },
                "images": {
                  "type": "Slice",
                  "fieldset": "Images",
                  "description": "Block for displaying one or more images.",
                  "icon": "image",
                  "display": "grid",
                  "non-repeat": {},
                  "repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "caption": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Caption",
                        "placeholder": "Optional - Caption below image"
                      }
                    }
                  }
                },
                "image_with_text": {
                  "type": "Slice",
                  "fieldset": "Image With Text",
                  "description": "Block for displaying an image along with small text.",
                  "icon": "web",
                  "display": "list",
                  "non-repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "Image"
                      }
                    },
                    "caption": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Caption",
                        "placeholder": "Optional - Caption below image"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Text",
                        "placeholder": "Text alongside the selected image."
                      }
                    }
                  },
                  "repeat": {}
                },
                "small_image": {
                  "type": "Slice",
                  "fieldset": "Small Image",
                  "description": "Single centerd image.",
                  "icon": "image",
                  "display": "list",
                  "non-repeat": {
                    "image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 2000
                        },
                        "thumbnails": [],
                        "label": "image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "featured_projects": {
                  "type": "Slice",
                  "fieldset": "Featured Projects",
                  "description": "Large project cards linked to their pages.",
                  "icon": "dashboard",
                  "display": "list",
                  "non-repeat": {
                    "project_1": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 1",
                        "placeholder": "Large project on left side"
                      }
                    },
                    "project_2": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 2",
                        "placeholder": "Large project on right side"
                      }
                    }
                  },
                  "repeat": {}
                },
                "small_projects": {
                  "type": "Slice",
                  "fieldset": "Small Projects",
                  "description": "Small project cards linked to their pages.",
                  "icon": "dashboard",
                  "display": "list",
                  "non-repeat": {
                    "project_1": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 1",
                        "placeholder": "First project to display"
                      }
                    },
                    "project_2": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 2",
                        "placeholder": "Second project to display"
                      }
                    },
                    "project_3": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 3",
                        "placeholder": "Third project to display"
                      }
                    },
                    "project_4": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project 4",
                        "placeholder": "Fourth project to display"
                      }
                    }
                  },
                  "repeat": {}
                },
                "mixed_sized_projects": {
                  "type": "Slice",
                  "fieldset": "Mixed Sized Projects",
                  "description": "Mixed sized project cards linked to their pages.",
                  "icon": "dashboard",
                  "display": "list",
                  "non-repeat": {
                    "large_project_side": {
                      "type": "Select",
                      "config": {
                        "options": ["Left", "Right"],
                        "default_value": "Left",
                        "label": "Large Project Side"
                      }
                    },
                    "large_project_1": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Large Project 1",
                        "placeholder": "Project to display"
                      }
                    },
                    "small_project_1": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Small Project 1",
                        "placeholder": "Project to display"
                      }
                    },
                    "small_project_2": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Small Project 2",
                        "placeholder": "Project to display"
                      }
                    },
                    "small_project_3": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Small Project 3",
                        "placeholder": "Project to display"
                      }
                    },
                    "small_project_4": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Small Project 4",
                        "placeholder": "Project to display"
                      }
                    }
                  },
                  "repeat": {}
                },
                "awards": {
                  "type": "Slice",
                  "fieldset": "Awards",
                  "description": "Repeatable list of awards.",
                  "icon": "grade",
                  "display": "list",
                  "non-repeat": {
                    "project_image_side": {
                      "type": "Select",
                      "config": {
                        "options": ["Left", "Right"],
                        "default_value": "Left",
                        "label": "Project Image Side",
                        "placeholder": "Side that the example project image will display"
                      }
                    },
                    "organization": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Organization",
                        "placeholder": "Name of granting organization"
                      }
                    },
                    "organization_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 1000
                        },
                        "thumbnails": [],
                        "label": "Organization Image"
                      }
                    },
                    "example_project": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "label": "Example Project",
                        "placeholder": "Appears alongside list of awards"
                      }
                    }
                  },
                  "repeat": {
                    "year": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Year",
                        "placeholder": "Year award was given"
                      }
                    },
                    "awards": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Awards",
                        "placeholder": "List of awards for year"
                      }
                    }
                  }
                },
                "branded_callout": {
                  "type": "Slice",
                  "fieldset": "Branded Callout",
                  "description": "Callout with text, logo, and optional button link.",
                  "icon": "folder_special",
                  "display": "list",
                  "non-repeat": {
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Light", "Dark"],
                        "default_value": "Light",
                        "label": "Color Scheme"
                      }
                    },
                    "text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Text",
                        "placeholder": "Large text in callout"
                      }
                    },
                    "button_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Button Text",
                        "placeholder": "Optional - Text inside button"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "placeholder": "Optional - URL associated with button click"
                      }
                    },
                    "background_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 1000
                        },
                        "thumbnails": [],
                        "label": "Background Image"
                      }
                    }
                  },
                  "repeat": {}
                },
                "callout_with_news": {
                  "type": "Slice",
                  "fieldset": "Callout With News",
                  "description": "Branded callout with featured news.",
                  "icon": "fiber_new",
                  "display": "list",
                  "non-repeat": {
                    "color_scheme": {
                      "type": "Select",
                      "config": {
                        "options": ["Light", "Dark"],
                        "default_value": "Light",
                        "label": "Color Scheme"
                      }
                    },
                    "callout_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Callout Text",
                        "placeholder": "Text shown in callout"
                      }
                    },
                    "callout_button_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Callout Button Text",
                        "placeholder": "Optional - Text inside callout button"
                      }
                    },
                    "callout_button_link": {
                      "type": "Link",
                      "config": {
                        "label": "Callout Button Link",
                        "allowTargetBlank": true,
                        "placeholder": "Optional - URL associated with button"
                      }
                    },
                    "featured_news": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["News"],
                        "label": "Featured News",
                        "placeholder": "News article to display"
                      }
                    }
                  },
                  "repeat": {}
                },
                "career_openings": {
                  "type": "Slice",
                  "fieldset": "Career Openings",
                  "description": "List of available career openings.",
                  "icon": "work",
                  "display": "list",
                  "non-repeat": {
                    "evergreen_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, heading2, strong, em, hyperlink, embed, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Evergreen Text",
                        "placeholder": "Optional - Text to display at all times below current openings"
                      }
                    }
                  },
                  "repeat": {
                    "position_title": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Position Title",
                        "placeholder": "Official title"
                      }
                    },
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Description",
                        "placeholder": "Concise info about position"
                      }
                    },
                    "qualifications": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Qualifications",
                        "placeholder": "List of qualifications"
                      }
                    },
                    "to_apply_text": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, hyperlink, list-item, o-list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "To Apply Text",
                        "placeholder": "Instructions to apply"
                      }
                    }
                  }
                },
                "featured_news_post": {
                  "type": "Slice",
                  "fieldset": "Featured News Post",
                  "description": "Large news post with image.",
                  "icon": "art_track",
                  "display": "list",
                  "non-repeat": {
                    "news_post": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["News"],
                        "label": "News Post",
                        "placeholder": "News post to feature"
                      }
                    }
                  },
                  "repeat": {}
                },
                "map": {
                  "type": "Slice",
                  "fieldset": "Map",
                  "description": "Map with contact information",
                  "icon": "map",
                  "display": "list",
                  "non-repeat": {
                    "map": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["map"],
                        "label": "Map",
                        "placeholder": "Map to display adjacent to text"
                      }
                    },
                    "address": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, hyperlink, list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Address",
                        "placeholder": "Office address"
                      }
                    },
                    "contact_information": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, hyperlink",
                        "allowTargetBlank": true,
                        "label": "Contact Information",
                        "placeholder": "Basic contact information - address, phone, etc"
                      }
                    },
                    "footnote": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, heading1, strong, hyperlink, list-item, o-list-item",
                        "allowTargetBlank": true,
                        "label": "Footnote",
                        "placeholder": "Small text footnote under the contact information"
                      }
                    }
                  },
                  "repeat": {}
                },
                "project_cards": {
                  "type": "Slice",
                  "fieldset": "Project Cards",
                  "description": "List of project card links with thumb image",
                  "icon": "view_module",
                  "display": "grid",
                  "non-repeat": {
                    "list_name": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "List Name",
                        "placeholder": "Heading displayed next to projects"
                      }
                    }
                  },
                  "repeat": {
                    "project": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Project",
                        "placeholder": "Project to display"
                      }
                    }
                  }
                },
                "service": {
                  "type": "Slice",
                  "fieldset": "Service",
                  "description": "Description of a service with featured project images.",
                  "icon": "art_track",
                  "display": "list",
                  "non-repeat": {
                    "text_align": {
                      "type": "Select",
                      "config": {
                        "options": ["right", "left"],
                        "default_value": "right",
                        "label": "Text Align"
                      }
                    },
                    "featured_image": {
                      "type": "Image",
                      "config": {
                        "constraint": {
                          "width": 1000
                        },
                        "thumbnails": [],
                        "label": "Featured Image"
                      }
                    },
                    "title1": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Title",
                        "placeholder": "Name of the service"
                      }
                    },
                    "description": {
                      "type": "StructuredText",
                      "config": {
                        "multi": "paragraph, strong, em, list-item, o-list-item, o-list-item",
                        "label": "Description",
                        "placeholder": "Description about the service"
                      }
                    },
                    "button_text": {
                      "type": "StructuredText",
                      "config": {
                        "single": "paragraph",
                        "label": "Button Text",
                        "placeholder": "Optional - Text within button"
                      }
                    },
                    "button_link": {
                      "type": "Link",
                      "config": {
                        "allowTargetBlank": true,
                        "label": "Button Link",
                        "placeholder": "Optional - URL associated with button"
                      }
                    }
                  },
                  "repeat": {
                    "featured_projects": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["page"],
                        "tags": ["Project"],
                        "label": "Featured Projects",
                        "placeholder": "Project related to this service"
                      }
                    }
                  }
                },
                "team_members": {
                  "type": "Slice",
                  "fieldset": "Team Members",
                  "description": "List of team members and their facts.",
                  "icon": "group",
                  "display": "grid",
                  "non-repeat": {
                    "heading": {
                      "type": "StructuredText",
                      "config": {
                        "single": "heading1",
                        "label": "Heading",
                        "placeholder": "Optional - Text above list of names"
                      }
                    }
                  },
                  "repeat": {
                    "team_member": {
                      "type": "Link",
                      "config": {
                        "select": "document",
                        "customtypes": ["team_member"],
                        "label": "Team Member",
                        "placeholder": "Team member to display"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "project_category": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "TItle"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "color": {
            "type": "Select",
            "config": {
              "options": ["Orange", "Green", "Blue", "Yellow", "Pink", "Brown", "Gray"],
              "default_value": "Orange",
              "label": "Color"
            }
          }
        }
      },
      "settings": {
        "Main": {
          "title": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Title"
            }
          },
          "site_name": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Site Name"
            }
          },
          "site_description": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Site Description"
            }
          },
          "site_copyright": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Site Copyright"
            }
          },
          "facebook_handle": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Facebook Handle"
            }
          },
          "twitter_handle": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Twitter Handle"
            }
          },
          "instagram_handle": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Instagram Handle"
            }
          },
          "linkedin_handle": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "LinkedIn Handle"
            }
          },
          "preview_map": {
            "type": "Group",
            "config": {
              "fields": {
                "api_id": {
                  "type": "Text",
                  "config": {
                    "label": "API ID"
                  }
                },
                "page": {
                  "type": "Link",
                  "config": {
                    "label": "Page"
                  }
                }
              },
              "label": "Preview Map"
            }
          }
        }
      },
      "team_member": {
        "Main": {
          "name": {
            "type": "StructuredText",
            "config": {
              "single": "heading1",
              "label": "Name",
              "placeholder": "Team member name"
            }
          },
          "uid": {
            "type": "UID",
            "config": {
              "label": "UID"
            }
          },
          "position_title": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "allowTargetBlank": true,
              "label": "Position Title",
              "placeholder": "Official job title"
            }
          },
          "personal_tweet": {
            "type": "StructuredText",
            "config": {
              "single": "paragraph",
              "label": "Personal Tweet",
              "placeholder": "140 characters or less that represents this team member"
            }
          },
          "headshot": {
            "type": "Image",
            "config": {
              "constraint": {
                "width": 1000
              },
              "thumbnails": [],
              "label": "Headshot"
            }
          },
          "certifications": {
            "type": "Group",
            "config": {
              "fields": {
                "certification_name": {
                  "type": "StructuredText",
                  "config": {
                    "single": "paragraph",
                    "label": "Certification Name",
                    "placeholder": "Certification acronym"
                  }
                }
              },
              "label": "Certifications"
            }
          }
        }
      }
    },
    "sliceZones": {
      "page": {
        "description": "Each page document in Prismic represents a unique and visitable page on your website.",
        "zones": {
          "body": "Body slices comprise the majority of the content on it's respective page."
        }
      }
    }
  }
}, {
  plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
  options: {
    "plugins": [],
    "trackingId": "UA-2543005-48"
  }
}, {
  plugin: require('../gatsby-browser.js'),
  options: {
    "plugins": []
  }
}];