// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m["default"] || m;
};

exports.components = {
  "component---src-templates-page-not-found-js": function componentSrcTemplatesPageNotFoundJs() {
    return import("../src/templates/pageNotFound.js"
    /* webpackChunkName: "component---src-templates-page-not-found-js" */
    );
  },
  "component---src-templates-home-js": function componentSrcTemplatesHomeJs() {
    return import("../src/templates/home.js"
    /* webpackChunkName: "component---src-templates-home-js" */
    );
  },
  "component---src-templates-project-js": function componentSrcTemplatesProjectJs() {
    return import("../src/templates/project.js"
    /* webpackChunkName: "component---src-templates-project-js" */
    );
  },
  "component---src-templates-news-js": function componentSrcTemplatesNewsJs() {
    return import("../src/templates/news.js"
    /* webpackChunkName: "component---src-templates-news-js" */
    );
  },
  "component---src-templates-horizontal-navigation-js": function componentSrcTemplatesHorizontalNavigationJs() {
    return import("../src/templates/horizontalNavigation.js"
    /* webpackChunkName: "component---src-templates-horizontal-navigation-js" */
    );
  },
  "component---src-templates-design-category-js": function componentSrcTemplatesDesignCategoryJs() {
    return import("../src/templates/designCategory.js"
    /* webpackChunkName: "component---src-templates-design-category-js" */
    );
  },
  "component---src-templates-news-category-js": function componentSrcTemplatesNewsCategoryJs() {
    return import("../src/templates/newsCategory.js"
    /* webpackChunkName: "component---src-templates-news-category-js" */
    );
  },
  "component---src-templates-floating-navigation-js": function componentSrcTemplatesFloatingNavigationJs() {
    return import("../src/templates/floatingNavigation.js"
    /* webpackChunkName: "component---src-templates-floating-navigation-js" */
    );
  },
  "component---src-templates-intro-text-js": function componentSrcTemplatesIntroTextJs() {
    return import("../src/templates/introText.js"
    /* webpackChunkName: "component---src-templates-intro-text-js" */
    );
  },
  "component---src-templates-button-navigation-js": function componentSrcTemplatesButtonNavigationJs() {
    return import("../src/templates/buttonNavigation.js"
    /* webpackChunkName: "component---src-templates-button-navigation-js" */
    );
  },
  "component---node-modules-walltowall-gatsby-theme-ww-slice-studio-src-templates-guide-js": function componentNodeModulesWalltowallGatsbyThemeWwSliceStudioSrcTemplatesGuideJs() {
    return import("../node_modules/@walltowall/gatsby-theme-ww-slice-studio/src/templates/guide.js"
    /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-slice-studio-src-templates-guide-js" */
    );
  },
  "component---node-modules-walltowall-gatsby-theme-ww-prismic-src-pages-preview-js": function componentNodeModulesWalltowallGatsbyThemeWwPrismicSrcPagesPreviewJs() {
    return import("../node_modules/@walltowall/gatsby-theme-ww-prismic/src/pages/preview.js"
    /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-prismic-src-pages-preview-js" */
    );
  },
  "component---node-modules-walltowall-gatsby-theme-ww-slice-studio-src-pages-admin-guides-js": function componentNodeModulesWalltowallGatsbyThemeWwSliceStudioSrcPagesAdminGuidesJs() {
    return import("../node_modules/@walltowall/gatsby-theme-ww-slice-studio/src/pages/admin/guides.js"
    /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-slice-studio-src-pages-admin-guides-js" */
    );
  },
  "component---node-modules-walltowall-gatsby-theme-ww-slice-studio-src-pages-admin-studio-index-js": function componentNodeModulesWalltowallGatsbyThemeWwSliceStudioSrcPagesAdminStudioIndexJs() {
    return import("../node_modules/@walltowall/gatsby-theme-ww-slice-studio/src/pages/admin/studio/index.js"
    /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-slice-studio-src-pages-admin-studio-index-js" */
    );
  },
  "component---src-pages-search-js": function componentSrcPagesSearchJs() {
    return import("../src/pages/search.js"
    /* webpackChunkName: "component---src-pages-search-js" */
    );
  },
  "component---src-pages-unpublished-preview-js": function componentSrcPagesUnpublishedPreviewJs() {
    return import("../src/pages/unpublishedPreview.js"
    /* webpackChunkName: "component---src-pages-unpublished-preview-js" */
    );
  }
};