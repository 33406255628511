import "core-js/modules/es6.array.sort";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.array.filter";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import staticQueryData from "../../public/static/d/3713923125.json";
import { useMemo } from 'react';
import { getMetadata } from 'helpers';
export var useNewsData = function useNewsData(categoryUID, _temp) {
  var _ref = _temp === void 0 ? {} : _temp,
      excludeUIDs = _ref.excludeUIDs;

  var queryData = staticQueryData.data;
  return useMemo(function () {
    var _queryData$allPrismic, _nodes, _nodes$filter, _nodes2, _nodes2$filter, _nodes3, _nodes3$sort;

    var nodes = queryData === null || queryData === void 0 ? void 0 : (_queryData$allPrismic = queryData.allPrismicPage) === null || _queryData$allPrismic === void 0 ? void 0 : _queryData$allPrismic.nodes;
    if (categoryUID) nodes = (_nodes = nodes) === null || _nodes === void 0 ? void 0 : (_nodes$filter = _nodes.filter) === null || _nodes$filter === void 0 ? void 0 : _nodes$filter.call(_nodes, function (node) {
      var _metadata$primary, _metadata$primary$new, _metadata$primary$new2;

      var metadata = getMetadata('PrismicPageMainNews', node);
      return (metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : (_metadata$primary$new = _metadata$primary.news_category) === null || _metadata$primary$new === void 0 ? void 0 : (_metadata$primary$new2 = _metadata$primary$new.document) === null || _metadata$primary$new2 === void 0 ? void 0 : _metadata$primary$new2.uid) === categoryUID;
    });
    if (excludeUIDs) nodes = (_nodes2 = nodes) === null || _nodes2 === void 0 ? void 0 : (_nodes2$filter = _nodes2.filter) === null || _nodes2$filter === void 0 ? void 0 : _nodes2$filter.call(_nodes2, function (node) {
      return !excludeUIDs.includes(node === null || node === void 0 ? void 0 : node.uid);
    });
    return (_nodes3 = nodes) === null || _nodes3 === void 0 ? void 0 : (_nodes3$sort = _nodes3.sort) === null || _nodes3$sort === void 0 ? void 0 : _nodes3$sort.call(_nodes3, function (a, b) {
      var _getMetadata, _getMetadata$primary, _getMetadata2, _getMetadata2$primary;

      var dateA = (_getMetadata = getMetadata('PrismicPageMainNews', a)) === null || _getMetadata === void 0 ? void 0 : (_getMetadata$primary = _getMetadata.primary) === null || _getMetadata$primary === void 0 ? void 0 : _getMetadata$primary.raw_date;
      var dateB = (_getMetadata2 = getMetadata('PrismicPageMainNews', b)) === null || _getMetadata2 === void 0 ? void 0 : (_getMetadata2$primary = _getMetadata2.primary) === null || _getMetadata2$primary === void 0 ? void 0 : _getMetadata2$primary.raw_date;
      if (dateA < dateB) return 1;
      if (dateA > dateB) return -1;
      return 0;
    });
  }, [categoryUID, excludeUIDs, queryData]);
};