import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { SVG } from 'system';
import { theme } from 'src/theme';
import { ReactComponent as AssetLogoTextSVG } from 'src/assets/logo-text.svg';
var colorSchemes = {
  light: {
    firstTextColor: theme.colors.white,
    secondTextColor: theme.colors.gray[10]
  },
  blue: {
    firstTextColor: theme.colors.white,
    secondTextColor: theme.colors.blue[40]
  }
};

var _StyledSVG = _styled(SVG).withConfig({
  displayName: "LogoText___StyledSVG",
  componentId: "gnm8nn-0"
})(["svg{>:first-child{fill:", ";}>:last-child{fill:", ";}}"], function (p) {
  return p._css;
}, function (p) {
  return p._css2;
});

export var LogoText = function LogoText(_ref) {
  var _ref$colorScheme = _ref.colorScheme,
      colorScheme = _ref$colorScheme === void 0 ? 'light' : _ref$colorScheme,
      props = _objectWithoutPropertiesLoose(_ref, ["colorScheme"]);

  var scheme = colorSchemes[colorScheme];
  return React.createElement(_StyledSVG, Object.assign({
    x: 210.86,
    y: 17.52,
    width: "10rem",
    svg: AssetLogoTextSVG,
    color: scheme.color,
    transitionProperty: "color"
  }, props, {
    _css: scheme.firstTextColor,
    _css2: scheme.secondTextColor
  }));
};