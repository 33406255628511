import "core-js/modules/es6.array.sort";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.array.filter";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import staticQueryData from "../../public/static/d/4110080104.json";
import { useMemo } from 'react';
import { getMetadata } from 'helpers';
export var useProjects = function useProjects(categoryUID, _temp) {
  var _ref = _temp === void 0 ? {} : _temp,
      excludeUIDs = _ref.excludeUIDs;

  var queryData = staticQueryData.data;
  return useMemo(function () {
    var _queryData$allPrismic, _projects, _projects$filter, _projects2, _projects2$filter, _projects3, _projects3$sort;

    var projects = queryData === null || queryData === void 0 ? void 0 : (_queryData$allPrismic = queryData.allPrismicPage) === null || _queryData$allPrismic === void 0 ? void 0 : _queryData$allPrismic.nodes;
    if (categoryUID) projects = (_projects = projects) === null || _projects === void 0 ? void 0 : (_projects$filter = _projects.filter) === null || _projects$filter === void 0 ? void 0 : _projects$filter.call(_projects, function (project) {
      var _metadata$items, _metadata$items$map;

      var metadata = getMetadata('PrismicPageMainProject', project);
      var projectCategoryUIDs = metadata === null || metadata === void 0 ? void 0 : (_metadata$items = metadata.items) === null || _metadata$items === void 0 ? void 0 : (_metadata$items$map = _metadata$items.map) === null || _metadata$items$map === void 0 ? void 0 : _metadata$items$map.call(_metadata$items, function (item) {
        var _item$project_categor, _item$project_categor2;

        return item === null || item === void 0 ? void 0 : (_item$project_categor = item.project_category) === null || _item$project_categor === void 0 ? void 0 : (_item$project_categor2 = _item$project_categor.document) === null || _item$project_categor2 === void 0 ? void 0 : _item$project_categor2.uid;
      });
      return projectCategoryUIDs.includes(categoryUID);
    });
    if (excludeUIDs) projects = (_projects2 = projects) === null || _projects2 === void 0 ? void 0 : (_projects2$filter = _projects2.filter) === null || _projects2$filter === void 0 ? void 0 : _projects2$filter.call(_projects2, function (project) {
      return !excludeUIDs.includes(project === null || project === void 0 ? void 0 : project.uid);
    });
    return (_projects3 = projects) === null || _projects3 === void 0 ? void 0 : (_projects3$sort = _projects3.sort) === null || _projects3$sort === void 0 ? void 0 : _projects3$sort.call(_projects3, function (a, b) {
      var _a$data, _a$data$title, _b$data, _b$data$title;

      var aTitle = a === null || a === void 0 ? void 0 : (_a$data = a.data) === null || _a$data === void 0 ? void 0 : (_a$data$title = _a$data.title) === null || _a$data$title === void 0 ? void 0 : _a$data$title.text;
      var bTitle = b === null || b === void 0 ? void 0 : (_b$data = b.data) === null || _b$data === void 0 ? void 0 : (_b$data$title = _b$data.title) === null || _b$data$title === void 0 ? void 0 : _b$data$title.text;
      if (aTitle < bTitle) return -1;
      if (aTitle > bTitle) return 1;
      return 0;
    });
  }, [categoryUID, excludeUIDs, queryData]);
};