import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { linearScale } from 'styled-system-scale';
import { Box, Image } from 'system';
import { HTMLContent } from 'src/components';
export var CaptionImage = function CaptionImage(_ref) {
  var src = _ref.src,
      alt = _ref.alt,
      fluid = _ref.fluid,
      captionHTML = _ref.captionHTML,
      props = _objectWithoutPropertiesLoose(_ref, ["src", "alt", "fluid", "captionHTML"]);

  return React.createElement(Box, Object.assign({
    as: "figure"
  }, props), React.createElement(Image, {
    src: src,
    alt: alt,
    fluid: fluid,
    height: "auto",
    maxHeight: linearScale('30rem', '50rem', {
      count: 5
    })
  }), captionHTML && React.createElement(HTMLContent, {
    html: captionHTML,
    componentOverrides: {
      p: function p(Comp) {
        return function (props) {
          return React.createElement(Comp, Object.assign({
            as: "figcaption"
          }, props));
        };
      }
    },
    fontSizeScale: "s",
    fontWeight: "light",
    color: "gray.70",
    mtScale: "t"
  }));
};